import "./MapButton.css";

export interface IMapButtonProps {
  onPress: () => void;
  icon: string;
}

const MapButton = (props: IMapButtonProps) => {
  const { onPress, icon } = props;

  return (
    <button onClick={onPress} className="map-button-btn">
      <img src={icon} alt="icon" className="map-button-img" />
    </button>
  );
};

export default MapButton;
