import { RouteEnum } from "@navigators";

const routeParser = (
  route: RouteEnum | string,
  value: string,
  replacer = ":id"
): string => {
  return route.replace(replacer, value);
};

export { routeParser };
