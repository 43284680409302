import Shifts from "./Shifts.tsx";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import {
  categoryAction,
  shiftDetailsAction,
  shiftsAction,
} from "@store/actions";
import { ITypesProps, IState } from "./Types.ts";

const mapStateToProps = (state: IState): ITypesProps => ({
  isLoadingCategory: state?.category?.isLoading,
  listCategory: state?.category?.list,
  activeCategory: state?.category?.activeCategory,
  listShifts: state?.shifts?.list,
  isLoadingShifts: state?.shifts?.isLoading,
});

const mapDispatchToProps = (dispatch: Dispatch): ITypesProps => ({
  categoryRequest: () => dispatch(categoryAction.categoryRequest()),
  choseCategory: (choseCategory) =>
    dispatch(categoryAction.choseCategory(choseCategory)),
  shiftsRequest: (activeCategory) =>
    dispatch(shiftsAction.shiftsRequest(activeCategory)),
  clearShiftDetails: () => dispatch(shiftDetailsAction.clearShiftDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Shifts);
