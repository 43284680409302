import "./Header.css";
import Logo from "@assets/logo.svg";

export interface IHeader {
  isAbsolute?: boolean;
}

const Header = (props: IHeader) => {
  const { isAbsolute = false } = props;

  return (
    <nav
      className={isAbsolute ? "header-container-absolute" : "header-container"}
    >
      <img src={Logo} alt="Kweek logo" className="header-img" />
    </nav>
  );
};

export default Header;
