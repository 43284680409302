import CompanyDetails from "./CompanyDetails.tsx";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { ITypesProps, IState } from "./Types.ts";
import { companyDetailsAction } from "@store/actions";

const mapStateToProps = (state: IState): ITypesProps => ({
  isLoading: state?.companyDetails?.isLoading,
  data: state?.companyDetails?.data,
  isShiftLoading: state?.companyDetails?.isShiftLoading,
  list: state?.companyDetails?.list,
});

const mapDispatchToProps = (dispatch: Dispatch): ITypesProps => ({
  getCompanyById: (id) => dispatch(companyDetailsAction.getCompanyById(id)),
  clearCompanyDetails: () =>
    dispatch(companyDetailsAction.clearCompanyDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
