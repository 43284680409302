import "./Shifts.css";
import {
  CategoryContainer,
  Header,
  ShiftsList,
  SubHeaderWithMap,
} from "@components";
import { ITypesProps } from "./Types.ts";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RouteEnum, routeParser } from "@navigators";
import { sliceCategoriesUrl } from "@utils";

const Shifts = (props: ITypesProps) => {
  const {
    isLoadingCategory,
    listCategory,
    clearShiftDetails,
    categoryRequest,
    choseCategory,
    activeCategory,
    listShifts,
    isLoadingShifts,
    shiftsRequest,
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    !listCategory?.length && categoryRequest?.();
    !listShifts?.length && shiftsRequest?.(activeCategory);

    clearShiftDetails?.();
  }, []);

  const handleShiftPress = (id: string) => {
    navigate(routeParser(RouteEnum.SHIFTS_DETAILS, id));
  };

  useEffect(() => {
    if (activeCategory) {
      history.pushState(null, "", sliceCategoriesUrl(activeCategory));
    }
  }, [activeCategory]);

  return (
    <>
      <Header />
      <div className="shifts-subheaderwithmap-container">
        <SubHeaderWithMap />

        <div className="shifts-main-list-box">
          <CategoryContainer
            isLoading={isLoadingCategory}
            list={listCategory}
            choseCategory={choseCategory}
            activeCategory={activeCategory}
            isWeb
          />
          <ShiftsList
            isLoading={isLoadingShifts}
            list={listShifts}
            onPress={handleShiftPress}
            isWeb
          />
        </div>
      </div>
    </>
  );
};

export default Shifts;
