import { put, call } from "redux-saga/effects";
import { categoryAction } from "@store/actions";
import { CategoryService } from "@services";

export function* CategoryRequestSaga(): any {
  try {
    const res = yield call(CategoryService.getCategory);
    if (res.data?.length > 0) {
      yield put(categoryAction.onCategorySuccess(res.data));
    } else {
      yield put(categoryAction.onCategoryError());
    }
  } catch (_) {
    yield put(categoryAction.onCategoryError());
  }
}
