import { put } from "redux-saga/effects";
import { categoryAction } from "@store/actions";
import { Constants } from "@configurations";

export function* findCategories(): any {
  try {
    const paramsString = window.location.href.split("?")?.[1];
    const categories: number[] = [];
    if (paramsString) {
      const divideParamsString = paramsString?.split("&");
      divideParamsString?.forEach((item: string) => {
        const subKeyString = item?.split("=");
        if (
          subKeyString?.[0]
            ?.toLowerCase()
            ?.startsWith(Constants.CATEGORIES_FIND_STRING) &&
          subKeyString?.[1] !== null
        ) {
          const chosenCategories = subKeyString?.[1];
          const numbers = chosenCategories?.match(/\d+/g)?.map(Number);
          if (numbers && numbers.length > 0) {
            for (let i = 0; i < numbers.length; i++) {
              categories.push(numbers[i]);
            }
          }
        }
      });
    }
    yield put(categoryAction.findCategories(categories));
  } catch (_) {
    console.log("Can't find categories, error bounder");
  }
}
