import { shiftDetails } from "@store/types";

const initialState: shiftDetails.IInitialState = {};

const shiftsDetailsReducer = (
  state = initialState,
  action: shiftDetails.IAction
) => {
  switch (action.type) {
    case shiftDetails.ActionTypes.GET_SHIFT_BY_ID: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case shiftDetails.ActionTypes.ON_SHIFT_BY_ID_SUCCESS: {
      const oldState = { ...state };
      delete oldState.isLoading;
      return {
        ...oldState,
        data: action.data,
      };
    }
    case shiftDetails.ActionTypes.SHIFT_BY_ID_ERROR: {
      const oldState = { ...state };
      delete oldState.isLoading;
      return {
        ...oldState,
      };
    }
    case shiftDetails.ActionTypes.CLEAR_SHIFT_DETAILS: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default shiftsDetailsReducer;
