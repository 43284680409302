import { v4 as uuidv4 } from "uuid";

enum CookieKey {
  USER_UID = "user_uid",
  LARAVEL_SESSION = "laravel_session",
}

enum CookieAge {
  DEATH_STAR = "46135573200",
  CHEWBACCA = "1800",
}

const setCookie = (key: CookieKey, age: CookieAge, value = uuidv4()): void => {
  document.cookie = `${key}=${value}; max-age=${age}; SameSite=None; secure`;
};

const getCookie = (key: CookieKey): string | undefined => {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" + key.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export { setCookie, CookieKey, getCookie, CookieAge };
