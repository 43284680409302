import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistReducer, persistStore } from "redux-persist";
import logger from "redux-logger";
import RootState from "./reducers";
import { RootSaga } from "@sagas";
import storage from "redux-persist/lib/storage";
import { Env } from "@configurations";

const persistConfig = {
  key: "rootStore",
  storage: storage,
  // whitelist: [""],
  blacklist: [
    "category",
    "app",
    "shifts",
    "shiftDetails",
    "map",
    "anal",
    "companyDetails",
  ],
};

export default (rootReducer: typeof RootState, rootSaga: RootSaga) => {
  const middleware: any = [];
  const enhancers: any = [];
  if (Env.NODE_ENV === `development`) {
    middleware.push(logger);
  }
  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);
  enhancers.push(applyMiddleware(...middleware));
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, {}, compose<any>(...enhancers));
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};
