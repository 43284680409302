import "./PanelBackdrop.css";
import ArrowRight from "@assets/fillArrowRight.svg";

export interface IPanelBackdrop {
  onPress?: () => void;
}
const PanelBackdrop = (props: IPanelBackdrop) => {
  const { onPress } = props;

  return (
    <button onClick={onPress} className="panel-backdrop-container">
      <img
        src={ArrowRight}
        alt="fill arrow right"
        className="panel-backdrop-img"
      />
      <p className="panel-backdrop-title">Назад</p>
    </button>
  );
};

export default PanelBackdrop;
