import "./MProfessionHeader.css";
import { IShiftDetailsItem } from "@store/types/shiftDetails/Interfaces.ts";
import { ImageRoom } from "@components";
import { calculatePrice } from "@utils";

export interface IMProfessionHeader {
  data: IShiftDetailsItem;
}

const MProfessionHeader = (props: IMProfessionHeader) => {
  const { data } = props;

  return (
    <div className="m-profession-header-main-container">
      <div>
        <p className="m-profession-header-main-title">{data?.profession}</p>
        <p className="m-profession-header-main-price">
          {calculatePrice(data?.price_month)}
        </p>
      </div>
      <ImageRoom
        src={data?.office?.logo?.url}
        alt="company logo"
        className="m-profession-header-img"
      />
    </div>
  );
};

export default MProfessionHeader;
