import { put } from "redux-saga/effects";
import { appAction } from "@store/actions";
import { Constants } from "@configurations";

export function* findUtmLabelSaga(): any {
  const utmArray: Array<{
    key?: string;
    value?: string;
  }> = [];

  try {
    const utmString = window.location.href.split("?")?.[1];
    if (utmString) {
      const divideUtmString = utmString?.split("&");
      divideUtmString?.forEach((item: string) => {
        const subKeyString = item?.split("=");
        if (
          subKeyString?.[0]
            ?.toLowerCase()
            ?.startsWith(Constants.UTM_FIND_STRING)
        ) {
          utmArray.push({
            key: subKeyString?.[0],
            value: subKeyString?.[1],
          });
        }
      });
    }
    if (utmArray?.length > 0) {
      yield put(appAction.implementUtmArray(utmArray));
    }
  } catch (_) {
    console.log("Can't find utm, error bounder");
  }
}
