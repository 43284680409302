const generateSubmitLink = (
  link: string,
  utmArray?: Array<{
    key?: string;
    value?: string;
  }>
): string => {
  let res: string = link;

  if (utmArray && utmArray?.length > 0) {
    utmArray.forEach((item: { key?: string; value?: string }): void => {
      res += `&${item?.key}=${item?.value}`;
    });
  }

  return res;
};

export { generateSubmitLink };
