import "./ShiftButtonGroup.css";
import { Env } from "@configurations";

export interface IShiftButtonGroupProps {
  acceptTitle: string;
  href: string;
}
const ShiftButtonGroup = (props: IShiftButtonGroupProps) => {
  const { acceptTitle, href } = props;

  return (
    <div className="shift-button-group-container">
      <a
        href={Env.TELEGRAM_BOT_LINK}
        target="_blank"
        className="shift-button-group-btn shift-button-group-help-btn"
      >
        <p className="shift-button-group-title">Помощь</p>
      </a>

      <a href={href} className="shift-button-group-btn" target="_blank">
        <p className="shift-button-group-title">{acceptTitle}</p>
      </a>
    </div>
  );
};

export default ShiftButtonGroup;
