import "./App.css";
import { ExternalController, RouteEnum } from "@navigators";
import { useDispatch, useSelector } from "react-redux";
import { appAction } from "@store/actions";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  NotFound,
  StarWayRoute,
  MShiftsDetails,
  MShifts,
  MShiftsMap,
  Shifts,
  ShiftsMap,
  Wait,
  MCompanyDetails,
  CompanyDetails,
} from "@pages";
import { useEffect, useRef } from "react";
import { Constants } from "@configurations";
import { RootState } from "@store/reducers";
import { Toaster } from "react-hot-toast";

function App() {
  const dispatch = useDispatch();
  const isWeb = useSelector<RootState, boolean>(
    (state): boolean => state.app.isWeb!
  );
  const isWebRef = useRef(isWeb);
  useEffect(() => {
    isWebRef.current = isWeb;
  }, [isWeb]);
  const updateDimensions = () => {
    if (
      window.innerWidth > Constants.MAX_MOBILE_WIDTH &&
      window.innerHeight > Constants.MAX_MOBILE_HEIGHT
    ) {
      !isWebRef.current && dispatch(appAction.setIsWebScreen(true));
    } else {
      isWebRef.current && dispatch(appAction.setIsWebScreen(false));
    }
  };

  const updateEndRecordTime = (ev: BeforeUnloadEvent) => {
    ev.preventDefault();
    localStorage.setItem("endRecordTime", String(Date.now()));
  };

  useEffect(() => {
    dispatch(appAction.startup());
    window.addEventListener("resize", updateDimensions);
    window.addEventListener("beforeunload", updateEndRecordTime);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener("beforeunload", updateEndRecordTime);
    };
  }, []);

  const generateRoute = () => {
    switch (isWeb) {
      case true:
      case false: {
        return [
          {
            path: RouteEnum.STAR,
            element: <StarWayRoute />,
          },
          {
            path: RouteEnum.NOT_FOUND,
            element: <ExternalController content={<NotFound />} />,
          },
          {
            path: RouteEnum.SHIFTS,
            element: (
              <ExternalController content={isWeb ? <Shifts /> : <MShifts />} />
            ),
          },
          {
            path: RouteEnum.SHIFTS_DETAILS,
            element: (
              <ExternalController
                content={isWeb ? <ShiftsMap /> : <MShiftsDetails />}
              />
            ),
          },
          {
            path: RouteEnum.SHIFTS_MAP,
            element: (
              <ExternalController
                content={isWeb ? <ShiftsMap /> : <MShiftsMap />}
              />
            ),
          },
          {
            path: RouteEnum.SHIFTS_MAP_MOBILE_DETAILS,
            element: (
              <ExternalController
                content={isWeb ? <ShiftsMap /> : <MShiftsMap />}
              />
            ),
          },
          {
            path: RouteEnum.SHIFTS_MAP_DETAILS,
            element: (
              <ExternalController
                content={isWeb ? <ShiftsMap /> : <MShiftsMap />}
              />
            ),
          },
          {
            path: RouteEnum.COMPANY_DETAILS,
            element: (
              <ExternalController
                content={isWeb ? <CompanyDetails /> : <MCompanyDetails />}
              />
            ),
          },
        ];
      }
      default: {
        return [
          {
            path: RouteEnum.STAR,
            element: <Wait />,
          },
        ];
      }
    }
  };

  const router = createBrowserRouter(generateRoute());

  return (
    <>
      <RouterProvider router={router} />
      <Toaster />
    </>
  );
}

export default App;
