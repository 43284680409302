import "./MPanel.css";
import Sheet from "react-modal-sheet";
import { ImageRoom, Loader, LocationItem, ShiftsList } from "@components";
import { useMemo } from "react";
import { IShiftListItem } from "@store/types/shifts/Interfaces.ts";
import { IShiftDetailsItem } from "@store/types/shiftDetails/Interfaces.ts";
import Close from "@assets/Close.svg";
import { calculatePrice, ToastCall } from "@utils";
import { RouteEnum, routeParser } from "@navigators";
import { useNavigate } from "react-router-dom";

export interface IMPanel {
  type: "map" | "detail" | "loading" | "extraDetail";
  isLoadingShifts?: boolean;
  listShifts?: Array<IShiftListItem>;
  handleShiftPress: (id: string) => void;
  data?: IShiftDetailsItem;
  id?: string;
  addresses?: Array<string>;
  positionList?: Array<IShiftListItem>;
  isVisible: boolean;
  closeModal: () => void;
  addressId?: string;
}

const MPanel = (props: IMPanel) => {
  const {
    type,
    isVisible = false,
    closeModal,
    isLoadingShifts,
    positionList,
    listShifts,
    handleShiftPress,
    data,
    id,
    addresses,
    addressId,
  } = props;
  const navigate = useNavigate();

  const handlePress = (ids: string) => {
    const mainShiftAddress = routeParser(
      RouteEnum.SHIFTS_MAP_MOBILE_DETAILS,
      id!
    );
    navigate(routeParser(mainShiftAddress, ids, ":addressId"), {
      replace: true,
    });
  };

  const handleCopy = (id: string) => {
    navigator.clipboard.writeText(id).then(
      () => ToastCall.successCopy("Адрес смены скопирован"),
      () => {
        ToastCall.successCopy("Адрес смены скопирован");
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        KweekNative?.copyToClipboard(id);
      }
    );
  };

  const renderLocationItem = () => {
    return data?.multi_offices_data?.list?.map((item, index) => {
      return (
        <LocationItem
          key={item?.uid}
          index={index}
          data={item}
          onCopyPress={handleCopy}
          onPress={handlePress}
        />
      );
    });
  };

  const renderMPanel = () => {
    switch (type) {
      case "map": {
        return (
          <div className="m-panel-shift-list-container">
            <ShiftsList
              isLoading={isLoadingShifts}
              list={positionList?.length ? positionList : listShifts}
              onPress={handleShiftPress}
            />
          </div>
        );
      }
      case "detail": {
        return (
          <div className="m-panel-map-main-container">
            <div className="m-panel-map-container">
              <div>
                <p className="m-panel-profession-desc-profession">
                  {data!.profession}
                </p>
                <p className="m-panel-profession-desc-price">
                  {calculatePrice(data!.price_month)}
                </p>
                <div className="m-panel-profession-desc-company-line" />
                <div
                  className="m-panel-profession-desc-company-box"
                  onClick={() => {
                    navigate(
                      routeParser(RouteEnum.COMPANY_DETAILS, data!.office?.uid)
                    );
                  }}
                >
                  <ImageRoom
                    src={data!.office?.logo?.url}
                    alt="company logo"
                    className="m-profession-desc-company-img"
                  />
                  <p className="m-profession-desc-company-name">
                    {data!.office?.office_group_title}
                  </p>
                </div>
                <div className="m-panel-profession-desc-company-line" />
              </div>
              <div className="m-panel-map-list-location">
                {renderLocationItem()}
              </div>
            </div>
          </div>
        );
      }
      case "extraDetail": {
        const findItem = data?.multi_offices_data?.list?.filter(
          (v) => v?.uid === addressId
        )?.[0];

        return (
          <div className="m-panel-single-location-item">
            <LocationItem
              key={findItem?.uid}
              index={0}
              data={findItem}
              onCopyPress={handleCopy}
            />
          </div>
        );
      }
      default: {
        return (
          <div className="m-panel-loader-container">
            <Loader />
          </div>
        );
      }
    }
  };

  const memoMPanel = useMemo(renderMPanel, [
    isVisible,
    type,
    addresses,
    positionList,
    addressId,
  ]);

  const calcStyles = () => {
    switch (type) {
      case "detail": {
        return {
          backgroundColor: "rgba(51, 51, 51, 0.5)",
        };
      }
      default: {
        return {
          backgroundColor: "transparent",
        };
      }
    }
  };

  return (
    <Sheet
      id="bottom-sheet"
      detent="content-height"
      isOpen={isVisible}
      onClose={closeModal}
    >
      <Sheet.Container>
        <button onClick={closeModal} className="m-panel-close-btn">
          <img src={Close} alt="close button" className="m-panel-close-img" />
        </button>
        <Sheet.Header />
        <Sheet.Content>{memoMPanel}</Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop style={calcStyles()} />
    </Sheet>
  );
};

export default MPanel;
