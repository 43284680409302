import "./MShiftsDetails.css";
import { useParams } from "react-router-dom";
import { ITypesProps } from "./Types.ts";
import { useEffect } from "react";
import {
  Loader,
  MProfessionDesc,
  MSchedule,
  MAboutCompany,
  MShiftNonRequiredDesc,
  MInstructions,
  MLocation,
  ShiftButtonGroup,
  Tags,
} from "@components";
import { generateSubmitLink } from "@utils";

const MShiftsDetails = (props: ITypesProps) => {
  const { isLoading, getShiftById, data, utmArray } = props;
  const { id } = useParams();

  useEffect(() => {
    if (!data || data?.uid !== id) {
      getShiftById?.(id ?? "1");
    }
  }, []);

  if (!data || isLoading) {
    return (
      <div className="m-shifts-details-loader">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="m-shifts-details-container">
        {data!.tags && (
          <div className="m-shifts-details-tags-container">
            <Tags tags={data!.tags} absolute={false} />
          </div>
        )}

        <MProfessionDesc
          profession={data!.profession}
          companyLogo={data!.office?.logo?.url}
          companyName={data!.office?.office_group_title}
          priceMonth={data!.price_month}
          companyId={data!.office?.uid}
        />
        <MSchedule schedule={data!.timetable_hum} />
        <MAboutCompany description={data!.description} />
        <MShiftNonRequiredDesc
          tasks={data!.tasks}
          conditions={data!.conditions}
          requirement={data!.requirement}
        />
        <MInstructions data={data!.instructions} />
        <MLocation data={data!.multi_offices_data?.list} shiftId={id!} />
      </div>
      <ShiftButtonGroup
        acceptTitle={data!.ln_btn}
        href={generateSubmitLink(data!.ln_link, utmArray)}
      />
    </>
  );
};

export default MShiftsDetails;
