import "./LocationItem.css";
import { IShiftDetailsListItem } from "@store/types/shiftDetails/Interfaces.ts";
import Copy from "@assets/copy.svg";
import { calcAddress } from "@utils";

export interface ILocationItemProps {
  index: number;
  data?: IShiftDetailsListItem;
  onCopyPress?: (id: string) => void;
  onPress?: (id: string) => void;
}

const LocationItem = (props: ILocationItemProps) => {
  const { index, data, onCopyPress, onPress } = props;
  const editEmployerAddress = calcAddress(data!.address);

  return (
    <>
      {index !== 0 && <div className="location-item-line" />}
      <button
        className="location-item-btn"
        onClick={(e) => {
          e.stopPropagation();
          onPress?.(data!.uid);
        }}
      >
        <div className="location-item-top-container">
          <div
            className="location-item-line-metro-circle"
            style={{ backgroundColor: `#${data?.metro_station_line_color}` }}
          />
          <p className="location-item-metro-name">{data?.metro_station_name}</p>
        </div>

        <div className="location-item-bottom-container">
          <p className="location-item-office-address">{editEmployerAddress}</p>
          <div
            onClick={(e) => {
              e.stopPropagation();
              onCopyPress?.(editEmployerAddress);
            }}
            className="location-item-copy-btn"
          >
            <img src={Copy} alt="copy img" className="location-item-copy-img" />
          </div>
        </div>
      </button>
    </>
  );
};

export default LocationItem;
