import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import appReducer from "./app";
import categoryReducer from "./category";
import shiftsReducer from "./shifts";
import shiftsDetailsReducer from "./shiftDetails";
import mapReducer from "./map";
import analReducer from "./anal";
import companyDetailsReducer from "./companyDetails";

const categoryPersistConfig = {
  key: "category",
  storage: storage,
  blacklist: ["list"],
};

const appPersistConfig = {
  key: "app",
  storage: storage,
  whitelist: ["userUid", "laravelSession"],
};

const analPersistConfig = {
  key: "anal",
  storage: storage,
  blacklist: ["config"],
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  category: persistReducer(categoryPersistConfig, categoryReducer),
  shifts: shiftsReducer,
  shiftDetails: shiftsDetailsReducer,
  map: mapReducer,
  anal: persistReducer(analPersistConfig, analReducer),
  companyDetails: companyDetailsReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
