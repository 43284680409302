import ShiftsMap from "./ShiftsMap.tsx";

import { connect } from "react-redux";
import { Dispatch } from "redux";

import { shiftDetailsAction, shiftsAction, mapAction } from "@store/actions";
import { ITypesProps, IState } from "./Types.ts";

const mapStateToProps = (state: IState): ITypesProps => ({
  listShifts: state?.shifts?.list,
  isLoadingShifts: state?.shifts?.isLoading,
  isLoadingDetails: state?.shiftDetails?.isLoading,
  dataDetails: state?.shiftDetails?.data,
  isLoadingMap: state?.map?.isLoading,
  listMarkers: state?.map?.list,
  activeCategory: state?.category?.activeCategory,
  addresses: state?.shifts?.addresses,
  positionList: state?.shifts?.positionList,
  utmArray: state?.app?.utmArray,
});

const mapDispatchToProps = (dispatch: Dispatch): ITypesProps => ({
  shiftsRequest: (activeCategory, addresses) =>
    dispatch(shiftsAction.shiftsRequest(activeCategory, addresses)),
  getShiftById: (id) => dispatch(shiftDetailsAction.getShiftById(id)),
  getMarkers: () => dispatch(mapAction.getMarkers()),
  clearShiftDetails: () => dispatch(shiftDetailsAction.clearShiftDetails()),
  positionShiftsClean: () => dispatch(shiftsAction.positionShiftsClean()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsMap);
