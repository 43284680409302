import { useLiveQuery } from "dexie-react-hooks";
import { db } from "@dexie";
import { ImageService } from "@services";

export interface IImageRoom {
  src: string;
  alt?: string;
  className?: string;
}

const ImageRoom = (props: IImageRoom) => {
  const { src, className = "", alt = "" } = props;
  const url = useLiveQuery(async () => {
    let t;
    try {
      t = await db.imageDB.get(src);
      if (!t?.base64) {
        const base64Data = await ImageService.getImage(src);
        try {
          await db.imageDB.add({
            id: src,
            urlLink: src,
            base64: base64Data,
          });
          return base64Data;
        } catch (_) {
          return base64Data;
        }
      }
    } catch (_) {
      t = {
        base64: await ImageService.getImage(src),
      };
    }

    return t?.base64;
  });

  const alterUrl = src.endsWith(".svg")
    ? `data:image/svg+xml;base64,${url}`
    : `data:image/jpeg;base64,${url}`;

  return url && <img src={alterUrl} alt={alt} className={className} />;
};

export default ImageRoom;
