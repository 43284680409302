import "./MMapListButton.css";
import ListFormat from "@assets/listFormat.svg";

export interface IMMapListButton {
  onPress?: () => void;
  title?: string;
}

const MMapListButton = (props: IMMapListButton) => {
  const { onPress, title = "Списком" } = props;

  return (
    <button onClick={onPress} className="m-close-map-list-btn">
      <p className="m-close-map-list-title">{title}</p>
      <img
        src={ListFormat}
        alt="close button"
        className="m-close-map-list-img"
      />
    </button>
  );
};

export default MMapListButton;
