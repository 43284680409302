import { put, call } from "redux-saga/effects";
import { shiftDetailsAction } from "@store/actions";
import { ShiftsService } from "@services";
import { IShiftsRequest } from "@store/types/shiftDetails/Interfaces.ts";
import { RouteEnum } from "@navigators";

export function* GetShiftByIdSaga(action: IShiftsRequest): any {
  const { id } = action;
  try {
    const res = yield call(ShiftsService.getShiftById, id);
    if (res.data) {
      yield put(shiftDetailsAction.onShiftByIdSuccess(res.data));
    } else {
      yield put(shiftDetailsAction.shiftByIdError());
    }
  } catch (_) {
    yield put(shiftDetailsAction.shiftByIdError());
    window.location.href = RouteEnum.NOT_FOUND;
  }
}
