import { put, call } from "redux-saga/effects";
import { companyDetailsAction } from "@store/actions";
import { CompanyService } from "@services";
import { RouteEnum } from "@navigators";
import { IGetCompanyById } from "@store/types/companyDetails/Interfaces.ts";

export function* GetCompanyDetailsSaga(action: IGetCompanyById): any {
  const { id } = action;
  try {
    yield put(companyDetailsAction.getCompanyShiftsRequest(id));
    const res = yield call(CompanyService.getCompanyById, id);
    if (res.data) {
      yield put(companyDetailsAction.onCompanyByIdSuccess(res.data));
    } else {
      yield put(companyDetailsAction.companyByIdError());
    }
  } catch (_) {
    yield put(companyDetailsAction.companyByIdError());
    window.location.href = RouteEnum.NOT_FOUND;
  }
}
