import "./CircleArrow.css";
import ArrowRight from "@assets/arrowRight.svg";

export interface ICircleArrowProps {
  onPress: () => void;
}

const CircleArrow = (props: ICircleArrowProps) => {
  const { onPress } = props;

  return (
    <>
      <button onClick={onPress} className="circle-arrow-btn">
        <div className="circle-arrow-container">
          <img
            src={ArrowRight}
            alt="Arrow right"
            className="circle-arrow-img"
          />
        </div>
      </button>
      <div className="circle-arrow-background-box" />
    </>
  );
};

export default CircleArrow;
