import { useNavigate, useParams } from "react-router-dom";
import { ITypesProps } from "./Types.ts";
import { useEffect } from "react";
import {
  Header,
  Loader,
  EmptyList,
  PanelBackdrop,
  ShiftsList,
  ImageRoom,
} from "@components";
import "./CompanyDetails.css";
import { RouteEnum, routeParser } from "@navigators";
import parse from "html-react-parser";
import MultipleAbout from "@assets/multipleAbout.svg";

const CompanyDetails = (props: ITypesProps) => {
  const {
    isLoading,
    getCompanyById,
    data,
    clearCompanyDetails,
    isShiftLoading,
    list,
  } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    id && getCompanyById?.(id);

    return () => {
      clearCompanyDetails?.();
    };
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleShiftPress = (id: string) => {
    navigate(routeParser(RouteEnum.SHIFTS_DETAILS, id));
  };

  return (
    <>
      <Header />
      <div className="company-backdrop">
        <PanelBackdrop onPress={handleGoBack} />
      </div>

      <div className="company-main-box">
        <div className="company-left">
          {!data || isLoading ? (
            <div className="company-left-loader-container">
              <Loader />
            </div>
          ) : (
            <>
              <div className="company-head-container">
                <ImageRoom
                  src={data?.logo?.url ?? ""}
                  alt="company logo"
                  className="company-img"
                />
                <div>
                  <p className="company-name">{data?.title}</p>
                  {data?.site_url && (
                    <a
                      href={data?.site_url}
                      className="company-site-btn"
                      target="_blank"
                    >
                      {data?.site_url}
                    </a>
                  )}
                </div>
              </div>
              {data.description && (
                <>
                  <div className="m-company-line" />
                  <div className="company-about">
                    <img
                      src={MultipleAbout}
                      alt="company logo"
                      className="m-about-company-img"
                    />
                    <span className="m-about-company-description">
                      Описание
                    </span>
                  </div>

                  {parse(data.description)}
                </>
              )}
            </>
          )}
        </div>

        <div className="company-right">
          <ShiftsList
            isLoading={isShiftLoading}
            list={list}
            onPress={handleShiftPress}
            isWeb={false}
          />
          {list?.length === 0 && <EmptyList />}
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
