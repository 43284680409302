/**
 * @description removes the index from the address
 * @param {string} v starting address
 * @return {string} address without index
 */
const calcAddress = (v: string) => {
  return isNaN(Number(v.slice(0, 6))) ? v : v.slice(8);
};

export { calcAddress };
