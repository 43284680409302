import "./MShiftNonRequiredDesc.css";
import parse from "html-react-parser";
import { MSubTitle } from "@components";

export interface IMShiftNonRequiredDescProps {
  tasks?: string;
  conditions?: string;
  requirement?: string;
}

const MShiftNonRequiredDesc = (props: IMShiftNonRequiredDescProps) => {
  const { tasks, conditions, requirement } = props;

  return (
    <>
      {(tasks?.length !== 0 ||
        conditions?.length !== 0 ||
        requirement?.length !== 0) && (
        <div className="m-shift-non-required-m-top" />
      )}
      {tasks?.length !== 0 && (
        <>
          <MSubTitle title="Предстоящие задачи" />
          {parse(tasks!)}
          <div className="m-shift-non-required-line" />
        </>
      )}
      {conditions?.length !== 0 && (
        <>
          <MSubTitle title="Условия работы" />
          {parse(conditions!)}
          <div className="m-shift-non-required-line" />
        </>
      )}
      {requirement?.length !== 0 && (
        <>
          <MSubTitle title="Требования к кандидату" />
          {parse(requirement!)}
        </>
      )}
    </>
  );
};

export default MShiftNonRequiredDesc;
