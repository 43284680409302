import Dexie, { Table } from "dexie";

export interface Image {
  id?: string;
  urlLink: string;
  base64: string;
}

export class ImageStore extends Dexie {
  imageDB!: Table<Image>;

  constructor() {
    super("imageStoreDatabase");
    this.version(1).stores({
      imageDB: "++id, urlLink, base64", // Primary key and indexed props
    });
  }
}

export const db = new ImageStore();
