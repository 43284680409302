import { category } from "@store/types";

const initialState: category.IInitialState = {};

const categoryReducer = (state = initialState, action: category.IAction) => {
  switch (action.type) {
    case category.ActionTypes.CATEGORY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case category.ActionTypes.ON_CATEGORY_SUCCESS: {
      const oldState = { ...state };
      delete oldState.isLoading;
      return {
        ...oldState,
        list: action.list,
      };
    }
    case category.ActionTypes.ON_CATEGORY_ERROR: {
      const oldState = { ...state };
      delete oldState.isLoading;
      return {
        ...oldState,
      };
    }
    case category.ActionTypes.CHOSE_CATEGORY: {
      const oldState = { ...state };
      let chosen: Array<number> = [];
      if (oldState?.activeCategory) {
        if (oldState.activeCategory.includes(action.choseCategory)) {
          const index = oldState.activeCategory.indexOf(action.choseCategory);
          if (index > -1) {
            oldState.activeCategory.splice(index, 1);
            chosen = JSON.parse(JSON.stringify(oldState.activeCategory));
          }
        } else {
          chosen = [...oldState.activeCategory, action.choseCategory];
        }
      } else {
        chosen.push(action.choseCategory);
      }

      return {
        ...oldState,
        activeCategory: chosen,
      };
    }
    case category.ActionTypes.FIND_CATEGORIES: {
      return {
        ...state,
        activeCategory: action.activeCategory,
      };
    }
    default: {
      return state;
    }
  }
};

export default categoryReducer;
