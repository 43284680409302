import { anal } from "@store/types";
import {
  IEventType,
  INavigationChangeItem,
} from "@store/types/anal/Interfaces.ts";

export function analyticAddLine(
  routeName: string,
  eventType: IEventType
): anal.IAction {
  return {
    type: anal.ActionTypes.ANALYTIC_ADD_LINE,
    routeName,
    eventType,
  };
}

export function setAnalyticLine(
  data: Array<INavigationChangeItem>
): anal.IAction {
  return {
    type: anal.ActionTypes.SET_ANALYTIC_LINE,
    data,
  };
}

export function sendAnalyticData(): anal.IAction {
  return {
    type: anal.ActionTypes.SEND_ANALYTIC_DATA,
  };
}

export function clearAnalyticData(): anal.IAction {
  return {
    type: anal.ActionTypes.CLEAR_ANALYTIC_DATA,
  };
}
