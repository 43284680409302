import { category } from "@store/types";
import { ICategoryListItem } from "@store/types/category/Interfaces.ts";

export function categoryRequest(): category.IAction {
  return {
    type: category.ActionTypes.CATEGORY_REQUEST,
  };
}

export function onCategorySuccess(
  list: Array<ICategoryListItem>
): category.IAction {
  return {
    type: category.ActionTypes.ON_CATEGORY_SUCCESS,
    list,
  };
}

export function onCategoryError(): category.IAction {
  return {
    type: category.ActionTypes.ON_CATEGORY_ERROR,
  };
}

export function choseCategory(choseCategory: number): category.IAction {
  return {
    type: category.ActionTypes.CHOSE_CATEGORY,
    choseCategory,
  };
}

export function findCategories(activeCategory: number[]): category.IAction {
  return {
    type: category.ActionTypes.FIND_CATEGORIES,
    activeCategory,
  };
}
