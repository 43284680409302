import $HttpClient from "@client";

const ANAL_DATA_RESOURCES = {
  ANALYTICS: "api/v1/web/analytics",
};

const postAnalytics = async <T>(data: T) => {
  return $HttpClient.post(ANAL_DATA_RESOURCES.ANALYTICS, data);
};

export { postAnalytics };
