import { call, put, select } from "redux-saga/effects";
import { appAction } from "@store/actions";
import { Constants } from "@configurations";
import { CookieUtils } from "@utils";
import { ExternalDataService } from "@services";
import { findUtmLabelSaga } from "./FindUtmLabelSaga";
import { findCategories } from "./FindCategories.ts";

/**
 * @description startup saga
 */
export function* startup(): any {
  yield put(
    appAction.setIsWebScreen(
      window.innerWidth > Constants.MAX_MOBILE_WIDTH &&
        window.innerHeight > Constants.MAX_MOBILE_HEIGHT
    )
  );
  yield call(findUtmLabelSaga);
  yield call(findCategories);
  const state = yield select();
  const stateUserUid = state?.app?.userUid;
  const stateLaravelSession = state?.app?.laravelSession;

  // if cookie not persistence
  if (!stateUserUid || !stateLaravelSession) {
    CookieUtils.setCookie(
      CookieUtils.CookieKey.USER_UID,
      CookieUtils.CookieAge.DEATH_STAR
    );
    CookieUtils.setCookie(
      CookieUtils.CookieKey.LARAVEL_SESSION,
      CookieUtils.CookieAge.CHEWBACCA
    );
    const userUid = CookieUtils.getCookie(CookieUtils.CookieKey.USER_UID);
    const laravelSession = CookieUtils.getCookie(
      CookieUtils.CookieKey.LARAVEL_SESSION
    );
    yield put(appAction.setCookieData(userUid!, laravelSession!));
    if (!window.location.href.includes("utm")) {
      yield call(ExternalDataService.postExternalAnalytics, {
        link: window.location.href,
      });
    }
  }

  // if cookie persistence
  const userUid = CookieUtils.getCookie(CookieUtils.CookieKey.USER_UID);
  if (!userUid) {
    CookieUtils.setCookie(
      CookieUtils.CookieKey.USER_UID,
      CookieUtils.CookieAge.DEATH_STAR,
      stateUserUid
    );
  }
  const laravelSession = CookieUtils.getCookie(
    CookieUtils.CookieKey.LARAVEL_SESSION
  );
  if (!laravelSession) {
    CookieUtils.setCookie(
      CookieUtils.CookieKey.LARAVEL_SESSION,
      CookieUtils.CookieAge.CHEWBACCA
    );
    const laravelSessionNew = CookieUtils.getCookie(
      CookieUtils.CookieKey.LARAVEL_SESSION
    );
    yield put(appAction.setCookieData(stateUserUid!, laravelSessionNew!));
  }

  // end
  yield put(appAction.appIsReady());
}
