import "./Location.css";
import { IShiftDetailsItem } from "@store/types/shiftDetails/Interfaces.ts";
import {
  calcAdditionalAddresses,
  countForm,
  ToastCall,
  ToastPosition,
} from "@utils";
import { useNavigate } from "react-router-dom";
import { RouteEnum, routeParser } from "@navigators";
import { LocationItem, MSubTitle } from "@components";
import { useMemo, useState } from "react";

export interface ILocationProps {
  data?: IShiftDetailsItem["multi_offices_data"]["list"];
  shiftId: string;
}

const Location = (props: ILocationProps) => {
  const { data, shiftId } = props;
  const extraLength = calcAdditionalAddresses(data?.length);
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handlePress = (id: string) => {
    const mainShiftAddress = routeParser(
      RouteEnum.SHIFTS_MAP_MOBILE_DETAILS,
      shiftId
    );
    navigate(routeParser(mainShiftAddress, id, ":addressId"));
  };

  const handleCopy = (id: string) => {
    navigator.clipboard.writeText(id).then((_) => {
      ToastCall.successCopy(
        "Адрес смены скопирован",
        ToastPosition.BOTTOM_LEFT,
        "location-item-toast-position"
      );
    });
  };

  const renderLocationItem = () => {
    const list = isExpanded ? data : data?.slice(0, 3);
    return list?.map((item, index) => {
      return (
        <LocationItem
          key={item?.uid}
          index={index}
          data={item}
          onCopyPress={handleCopy}
          onPress={handlePress}
        />
      );
    });
  };

  const memoLocationItem = useMemo(renderLocationItem, [isExpanded]);

  const handleViewMorePress = () => {
    setIsExpanded(true);
  };

  return (
    <div className="location-container">
      <MSubTitle title="Расположение" />
      {memoLocationItem}
      {extraLength && !isExpanded && (
        <button
          className="location-extra-btn-box"
          onClick={handleViewMorePress}
        >
          <p className="location-extra-btn-text">{`Посмотреть +${extraLength} ${countForm(
            extraLength,
            ["адрес", "адреса", "адресов"]
          )} на карте`}</p>
        </button>
      )}
    </div>
  );
};

export default Location;
