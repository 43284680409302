import { put, call } from "redux-saga/effects";
import { companyDetailsAction } from "@store/actions";
import { ShiftsService } from "@services";
import { ICompanyShiftsRequest } from "@store/types/companyDetails/Interfaces.ts";

export function* GetCompanyShiftsSaga(action: ICompanyShiftsRequest): any {
  const { office } = action;
  try {
    const data = genFilter(office);
    const res = yield call(ShiftsService.getShifts, data);
    if (res.data?.items) {
      yield put(companyDetailsAction.onCompanyShiftsSuccess(res.data?.items));
    } else {
      yield put(companyDetailsAction.onCompanyShiftsError());
    }
  } catch (_) {
    yield put(companyDetailsAction.onCompanyShiftsError());
  }
}

const genFilter = (office?: string) => {
  return {
    filter: {
      office: office,
    },
  };
};
