import "./MHeader.css";
import Logo from "@assets/logo.svg";

const MHeader = () => {
  return (
    <nav className="m-header-container">
      <img src={Logo} alt="Kweek logo" className="m-header-img" />
    </nav>
  );
};

export default MHeader;
