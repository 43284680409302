import $HttpClient from "@client";

const MAP_RESOURCES = {
  LIST: "api/v1/web/shifts-mapExt",
};

const getMarkers = async <T>(data?: T) => {
  return $HttpClient.get(MAP_RESOURCES.LIST, data);
};

export { getMarkers };
