import { map } from "@store/types";

const initialState: map.IInitialState = {};

const mapReducer = (state = initialState, action: map.IAction) => {
  switch (action.type) {
    case map.ActionTypes.GET_MARKERS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case map.ActionTypes.ON_MARKERS_SUCCESS: {
      const oldState = { ...state };
      delete oldState.isLoading;
      return {
        ...oldState,
        list: action.list,
      };
    }
    case map.ActionTypes.ON_MARKERS_ERROR: {
      const oldState = { ...state };
      delete oldState.isLoading;
      return {
        ...oldState,
      };
    }
    default: {
      return state;
    }
  }
};

export default mapReducer;
