import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./src/App";
import { Provider } from "react-redux";
import createStore from "./src/store";
import "@picocss/pico/css/pico.min.css";

const { store } = createStore();

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
