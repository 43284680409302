import "./Panel.css";
import {
  Loader,
  ShiftsList,
  MProfessionDesc,
  MSchedule,
  MAboutCompany,
  MShiftNonRequiredDesc,
  MInstructions,
  Location,
  ShiftButtonGroup,
  PanelBackdrop,
  Tags,
} from "@components";
import { IShiftListItem } from "@store/types/shifts/Interfaces.ts";
import { IShiftDetailsItem } from "@store/types/shiftDetails/Interfaces.ts";
import { useNavigate } from "react-router-dom";
import { RouteEnum } from "@navigators";
import { useMemo } from "react";
import { generateSubmitLink } from "@utils";

export interface IPanel {
  type: "map" | "detail" | "loading";
  isLoadingShifts?: boolean;
  listShifts?: Array<IShiftListItem>;
  handleShiftPress: (id: string) => void;
  data?: IShiftDetailsItem;
  id?: string;
  clearShift?: () => void;
  addresses?: Array<string>;
  shiftsRequest?: () => void;
  positionList?: Array<IShiftListItem>;
  utmArray?: Array<{
    key?: string;
    value?: string;
  }>;
}
const Panel = (props: IPanel) => {
  const {
    type,
    listShifts,
    handleShiftPress,
    isLoadingShifts,
    data,
    id,
    clearShift,
    addresses,
    shiftsRequest,
    positionList,
    utmArray,
  } = props;
  const navigate = useNavigate();

  const handleBackFromDetailsPress = () => {
    navigate(RouteEnum.SHIFTS_MAP, { replace: true });
    clearShift?.();
  };

  const handleBackFromDetailsPressWithAddresses = () => {
    shiftsRequest?.();
  };

  const renderPanel = () => {
    switch (type) {
      case "map": {
        return (
          <div className="panel-map-container">
            {addresses ? (
              <div className="panel-map-container-backdrop-container">
                <PanelBackdrop
                  onPress={handleBackFromDetailsPressWithAddresses}
                />
              </div>
            ) : (
              <h2 className="panel-map-title">Работа</h2>
            )}
            <ShiftsList
              isLoading={isLoadingShifts}
              list={positionList?.length ? positionList : listShifts}
              onPress={handleShiftPress}
              isWeb
            />
          </div>
        );
      }
      case "detail": {
        return (
          <div className="panel-map-main-container">
            <div className="panel-map-main-container-backdrop-container">
              <PanelBackdrop onPress={handleBackFromDetailsPress} />
            </div>
            <div className="panel-map-container">
              <Tags tags={data!.tags} absolute={false} />
              <MProfessionDesc
                profession={data!.profession}
                companyLogo={data!.office?.logo?.url}
                companyName={data!.office?.office_group_title}
                priceMonth={data!.price_month}
                companyId={data!.office?.uid}
              />
              <MSchedule schedule={data!.timetable_hum} />
              <MAboutCompany description={data!.description} />
              <MShiftNonRequiredDesc
                tasks={data!.tasks}
                conditions={data!.conditions}
                requirement={data!.requirement}
              />
              <MInstructions data={data!.instructions} />
              <Location data={data!.multi_offices_data?.list} shiftId={id!} />
            </div>
            <ShiftButtonGroup
              acceptTitle={data!.ln_btn}
              href={generateSubmitLink(data!.ln_link, utmArray)}
            />
          </div>
        );
      }
      default: {
        return (
          <div className="panel-loader-container">
            <Loader />
          </div>
        );
      }
    }
  };

  const memoPanel = useMemo(renderPanel, [
    type,
    addresses,
    positionList,
    listShifts,
  ]);

  return <>{memoPanel}</>;
};

export default Panel;
