import { shiftDetails } from "@store/types";
import { IShiftDetailsItem } from "@store/types/shiftDetails/Interfaces.ts";

export function getShiftById(id: string): shiftDetails.IAction {
  return {
    type: shiftDetails.ActionTypes.GET_SHIFT_BY_ID,
    id,
  };
}

export function onShiftByIdSuccess(
  data: IShiftDetailsItem
): shiftDetails.IAction {
  return {
    type: shiftDetails.ActionTypes.ON_SHIFT_BY_ID_SUCCESS,
    data,
  };
}

export function shiftByIdError(): shiftDetails.IAction {
  return {
    type: shiftDetails.ActionTypes.SHIFT_BY_ID_ERROR,
  };
}

export function clearShiftDetails(): shiftDetails.IAction {
  return {
    type: shiftDetails.ActionTypes.CLEAR_SHIFT_DETAILS,
  };
}
