import "./CategoryItem.css";
import { ICategoryListItem } from "@store/types/category/Interfaces.ts";
import { countForm } from "@utils";
import { ImageRoom } from "@components";

const CategoryItem = (props: ICategoryListItem) => {
  const { id, url, title, count, onPress, isActive } = props;

  const handleClick = () => {
    onPress?.(id);
  };

  return (
    <button onClick={handleClick} className="category-item-button">
      <div
        className={
          isActive
            ? "category-item-container-active"
            : "category-item-container"
        }
      >
        <div className="category-item-top">
          <ImageRoom src={url} alt="Map image" className="category-item-img" />
        </div>
        <div className="category-item-bottom">
          <p className="category-item-title">{title}</p>
          <p className="category-item-count">
            {`${count} ${countForm(count, [
              "предложение",
              "предложения",
              "предложений",
            ])}`}
          </p>
        </div>
      </div>
    </button>
  );
};

export default CategoryItem;
