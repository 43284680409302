import { shifts } from "@store/types";
import { IShiftListItem } from "@store/types/shifts/Interfaces.ts";

export function shiftsRequest(
  activeCategory?: Array<number>,
  addresses?: Array<string>
): shifts.IAction {
  return {
    type: shifts.ActionTypes.SHIFTS_REQUEST,
    activeCategory,
    addresses,
  };
}

export function onShiftsSuccess(
  list: Array<IShiftListItem>,
  total: number,
  per_page: number,
  lastPage: number,
  currentPage: number
): shifts.IAction {
  return {
    type: shifts.ActionTypes.ON_SHIFTS_SUCCESS,
    list,
    total,
    per_page,
    lastPage,
    currentPage,
  };
}

export function onShiftsError(): shifts.IAction {
  return {
    type: shifts.ActionTypes.ON_SHIFTS_ERROR,
  };
}

export function onPositionShiftsSuccess(
  positionList: Array<IShiftListItem>,
  addresses?: Array<string>
): shifts.IAction {
  return {
    type: shifts.ActionTypes.ON_POSITION_SHIFTS_SUCCESS,
    positionList,
    addresses,
  };
}

export function positionShiftsClean(): shifts.IAction {
  return {
    type: shifts.ActionTypes.POSITION_SHIFTS_CLEAN,
  };
}

export function onShiftsSuccessMore(
  list: Array<IShiftListItem>,
  total: number,
  per_page: number,
  lastPage: number,
  currentPage: number
): shifts.IAction {
  return {
    type: shifts.ActionTypes.ON_SHIFTS_SUCCESS_MORE,
    list,
    total,
    per_page,
    lastPage,
    currentPage,
  };
}
