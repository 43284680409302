import $HttpClient from "@client";

const EXTERNAL_DATA_RESOURCES = {
  EXTERNAL_ANALYTICS: "api/v1/web/analytics/external",
};

const postExternalAnalytics = async <T>(data: T) => {
  return $HttpClient.postForm(EXTERNAL_DATA_RESOURCES.EXTERNAL_ANALYTICS, data);
};

export { postExternalAnalytics };
