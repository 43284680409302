import "./MInstructions.css";
import { IShiftDetailsItem } from "@store/types/shiftDetails/Interfaces.ts";
import Documents from "@assets/documents.svg";
import Clinch from "@assets/clinch.svg";

export interface IMInstructionsProps {
  data?: IShiftDetailsItem["instructions"];
}

const MInstructions = (props: IMInstructionsProps) => {
  const { data } = props;

  if (data?.length === 0) {
    return null;
  }

  const handlePress = () => {
    return data?.[0].attachment.url ?? "";
  };

  return (
    <div className="m-instructions-container">
      <div className="m-instructions-line" />

      <a href={handlePress()} className="m-instructions-btn" target="_blank">
        <div>
          <img src={Documents} alt="documents" className="m-instructions-img" />
          <span className="m-instructions-text">Инструкция к работе</span>
        </div>

        <img src={Clinch} alt="clinch" className="m-instructions-img" />
      </a>

      <div className="m-instructions-line" />
    </div>
  );
};

export default MInstructions;
