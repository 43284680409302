import { companyDetails } from "@store/types";
import { ICompanyDetails } from "@store/types/companyDetails/Interfaces.ts";
import { IShiftListItem } from "@store/types/shifts/Interfaces.ts";

export function getCompanyById(id: string): companyDetails.IAction {
  return {
    type: companyDetails.ActionTypes.GET_COMPANY_BY_ID,
    id,
  };
}

export function onCompanyByIdSuccess(
  data: ICompanyDetails
): companyDetails.IAction {
  return {
    type: companyDetails.ActionTypes.ON_COMPANY_BY_ID_SUCCESS,
    data,
  };
}

export function companyByIdError(): companyDetails.IAction {
  return {
    type: companyDetails.ActionTypes.COMPANY_BY_ID_ERROR,
  };
}

export function clearCompanyDetails(): companyDetails.IAction {
  return {
    type: companyDetails.ActionTypes.CLEAR_COMPANY_DETAILS,
  };
}

export function getCompanyShiftsRequest(
  office: string
): companyDetails.IAction {
  return {
    type: companyDetails.ActionTypes.COMPANY_SHIFTS_REQUEST,
    office,
  };
}

export function onCompanyShiftsSuccess(
  list: Array<IShiftListItem>
): companyDetails.IAction {
  return {
    type: companyDetails.ActionTypes.ON_COMPANY_SHIFTS_SUCCESS,
    list,
  };
}

export function onCompanyShiftsError(): companyDetails.IAction {
  return {
    type: companyDetails.ActionTypes.ON_COMPANY_SHIFTS_ERROR,
  };
}
