import { call, put, select } from "redux-saga/effects";
import { analAction } from "@store/actions";
import { AnalyticsService } from "@services";

export function* SendAnalyticDataSaga(): any {
  const state = yield select();
  const recoilState = state?.anal?.data;
  const recoilApp = state?.app;
  const oldCurrentSession = state?.anal?.currentSession;
  const oldCurrentStartRecord = state?.anal?.currentStartRecord;

  const calcData = {
    data: recoilState,
    appType: "web-app",
    session: oldCurrentSession,
    startRecord: oldCurrentStartRecord,
    userUid: recoilApp?.userUid,
    laravelSession: recoilApp?.laravelSession,
  };

  try {
    const isDevelopment = window.location.href.includes("localhost");
    if (!isDevelopment) {
      yield call(AnalyticsService.postAnalytics, calcData);
    }
    yield put(analAction.clearAnalyticData());
  } catch (_) {
    console.log("Can't add event");
  }
}
