import toast from "react-hot-toast";
import { JSX } from "react";

enum ToastPosition {
  TOP_LEFT = "top-left",
  TOP_CENTER = "top-center",
  TOP_RIGHT = "top-right",
  BOTTOM_LEFT = "bottom-left",
  BOTTOM_CENTER = "bottom-center",
  BOTTOM_RIGHT = "bottom-right",
}

/**
 * @description wild hot call toast
 */
const ToastCall = {
  successCopy: (
    message = "Успешно скопировано",
    position = ToastPosition.BOTTOM_CENTER,
    className = ""
  ) =>
    toast(message, {
      position: position,
      duration: 1500,
      className: `toast-success-copy ${className}`,
    }),
  customJSX: (jsx: JSX.Element, customClass: string): number => {
    const tId = toast.success(jsx, {
      position: ToastPosition.TOP_RIGHT,
      duration: Infinity,
      className: customClass,
    });
    return Number(tId);
  },
  clearCustomJSX: () => toast.remove(),
};

export { ToastCall, ToastPosition };
