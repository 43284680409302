import { put, call, select } from "redux-saga/effects";
import { mapAction } from "@store/actions";
import { MapService } from "@services";

export function* GetMarkersSaga(): any {
  const state = yield select();
  try {
    const res = yield call(MapService.getMarkers, {
      filter: {
        type_ex: "leadogeneration",
        categories: state?.category?.activeCategory,
      },
    });
    if (res.data) {
      yield put(mapAction.onMarkersSuccess(res.data));
    } else {
      yield put(mapAction.onMarkersError());
    }
  } catch (_) {
    yield put(mapAction.onMarkersError());
  }
}
