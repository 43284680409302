import { localPrice } from "@utils";

const calculateLabelText = (shiftsCount: number, price: number) => {
  if (shiftsCount < 10) {
    return `${genSpace(2)} ${shiftsCount} ${genSpace(5)} до ${localPrice(
      price
    )} ₽ / мес`;
  }
  if (shiftsCount < 100 && shiftsCount > 9) {
    return `${genSpace(1)} ${shiftsCount} ${genSpace(4)} до ${localPrice(
      price
    )} ₽ / мес`;
  }
  return `${shiftsCount} ${genSpace(3)} до ${localPrice(price)} ₽ / мес`;
};

const genSpace = (count: number) => {
  let str = "";
  for (let i = 0; i < count; i++) {
    str += String.fromCharCode(160);
  }
  return str;
};

export { calculateLabelText };
