import { Constants } from "@configurations";

const sliceCategoriesUrl = (categories: number[]) => {
  if (categories?.length > 0) {
    return `${window.location.origin}${window.location.pathname}?${Constants.CATEGORIES_FIND_STRING}=${categories}`;
  } else {
    return `${window.location.origin}${window.location.pathname}`;
  }
};

export { sliceCategoriesUrl };
