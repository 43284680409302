import "./CloseMap.css";
import Close from "@assets/Close.svg";

export interface ICloseMapProps {
  onPress: () => void;
}
const CloseMap = (props: ICloseMapProps) => {
  const { onPress } = props;

  return (
    <button onClick={onPress} className="close-map-btn">
      <p className="close-map-title">Закрыть карту</p>
      <img src={Close} alt="close button" className="close-map-img" />
    </button>
  );
};

export default CloseMap;
