import { useNavigate, useParams } from "react-router-dom";
import { ITypesProps } from "./Types.ts";
import { useEffect } from "react";
import {
  ImageRoom,
  Loader,
  MAboutCompany,
  ShiftsList,
  MEmptyList,
} from "@components";
import "./MCompanyDetails.css";
import { RouteEnum, routeParser } from "@navigators";

const MCompanyDetails = (props: ITypesProps) => {
  const {
    isLoading,
    getCompanyById,
    data,
    clearCompanyDetails,
    isShiftLoading,
    list,
  } = props;
  const { id } = useParams();

  const navigate = useNavigate();
  const handleShiftPress = (id: string) => {
    navigate(routeParser(RouteEnum.SHIFTS_DETAILS, id));
  };

  useEffect(() => {
    id && getCompanyById?.(id);

    return () => {
      clearCompanyDetails?.();
    };
  }, []);

  if (!data || isLoading) {
    return (
      <div className="m-shifts-details-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="m-company-main-container">
      <div className="m-company-head-container">
        <ImageRoom
          src={data?.logo?.url ?? ""}
          alt="company logo"
          className="m-company-img"
        />
        <div>
          <p className="m-company-name">{data?.title}</p>
          {data?.site_url && (
            <a
              href={data?.site_url}
              className="m-company-site-btn"
              target="_blank"
            >
              {data?.site_url}
            </a>
          )}
        </div>
      </div>

      {data.description && (
        <>
          <div className="m-company-line" />
          <MAboutCompany
            descTitle="Описание"
            isOpenAlready
            description={data.description}
          />
        </>
      )}

      <ShiftsList
        isLoading={isShiftLoading}
        list={list}
        onPress={handleShiftPress}
        isWeb={false}
      />

      {list?.length === 0 && <MEmptyList />}
    </div>
  );
};

export default MCompanyDetails;
