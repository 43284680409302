import "./ShiftsList.css";
import { IShiftListItem } from "@store/types/shifts/Interfaces.ts";
import { Loader, ShiftListItem } from "@components";
import { useMemo } from "react";

export interface IShiftsListProps {
  isLoading?: boolean;
  list?: Array<IShiftListItem>;
  onPress: (id: string) => void;
  isWeb?: boolean;
}

const ShiftsList = (props: IShiftsListProps) => {
  const { isLoading, list, onPress, isWeb } = props;

  const renderItems = () => {
    return list?.map((item) => {
      return (
        <ShiftListItem
          key={item?.uid}
          uid={item?.uid}
          onPress={onPress}
          price_month={item?.price_month}
          office={item?.office}
          profession={item?.profession}
          timetable_hum={item?.timetable_hum}
          isWeb={isWeb}
          tags={item?.tags}
        />
      );
    });
  };

  const memoItems = useMemo(renderItems, [isLoading, list]);

  if (isLoading) {
    return (
      <div className="shifts-list-container-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={isWeb ? "shifts-list-container" : "m-shifts-list-container"}
    >
      {memoItems}
    </div>
  );
};

export default ShiftsList;
