import MShiftsDetails from "./MShiftsDetails.tsx";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { shiftDetailsAction } from "@store/actions";
import { ITypesProps, IState } from "./Types.ts";

const mapStateToProps = (state: IState): ITypesProps => ({
  isLoading: state?.shiftDetails?.isLoading,
  data: state?.shiftDetails?.data,
  utmArray: state?.app?.utmArray,
});

const mapDispatchToProps = (dispatch: Dispatch): ITypesProps => ({
  getShiftById: (id) => dispatch(shiftDetailsAction.getShiftById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MShiftsDetails);
