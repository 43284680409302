import axios, { AxiosInstance, AxiosResponse } from "axios";
import { Domains } from "@configurations";
import { Buffer } from "buffer";
import { CookieUtils } from "@utils";

class $HttpClient {
  static instance: $HttpClient;
  readonly _client: AxiosInstance;
  private readonly _apiUrl: string;
  _userUid = "";
  _laravelSession = "";

  constructor() {
    this._apiUrl = Domains.getDomainBaseUrl();
    this._client = axios.create({
      baseURL: this._apiUrl,
    });
    $HttpClient.instance = this;
  }

  updateCookieSession(): void {
    const userUid = CookieUtils.getCookie(CookieUtils.CookieKey.USER_UID);
    const laravelSession = CookieUtils.getCookie(
      CookieUtils.CookieKey.LARAVEL_SESSION
    );

    // update USER_UID
    if (userUid) {
      this._userUid = userUid;
    } else {
      CookieUtils.setCookie(
        CookieUtils.CookieKey.USER_UID,
        CookieUtils.CookieAge.DEATH_STAR,
        this._userUid
      );
    }

    // update LARAVEL_SESSION
    if (laravelSession) {
      this._laravelSession = laravelSession;
      CookieUtils.setCookie(
        CookieUtils.CookieKey.LARAVEL_SESSION,
        CookieUtils.CookieAge.CHEWBACCA,
        laravelSession
      );
    } else {
      CookieUtils.setCookie(
        CookieUtils.CookieKey.LARAVEL_SESSION,
        CookieUtils.CookieAge.CHEWBACCA,
        this._laravelSession
      );
    }
  }

  // Request interceptor
  reqInterceptor() {
    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }

  static getInstance() {
    if ($HttpClient.instance) {
      return $HttpClient.instance;
    }
    return new $HttpClient();
  }

  // GET constructor
  async get<T>(url: string, data?: T): Promise<any> {
    this.updateCookieSession();

    const uri = `${this._apiUrl}${url}`;
    try {
      const response: AxiosResponse = await this._client.get(uri, {
        params: data,
        headers: {
          "User-Uid": this._userUid,
          "Laravel-Session": this._laravelSession,
        },
      });
      return response?.data;
    } catch (e) {
      this._processError(url, (e as Error).message);
    }
  }

  // POST constructor
  async post<T>(url: string, data: T): Promise<any> {
    this.updateCookieSession();
    const uri = `${this._apiUrl}${url}`;
    try {
      const response: AxiosResponse = await axios.post(uri, data, {
        headers: {
          "User-Uid": this._userUid,
          "Laravel-Session": this._laravelSession,
        },
      });
      return response?.data;
    } catch (e) {
      this._processError(url, (e as Error).message);
    }
  }

  // POST constructor form
  async postForm<T>(url: string, data: T): Promise<any> {
    this.updateCookieSession();
    const uri = `${this._apiUrl}${url}`;
    try {
      const response: AxiosResponse = await axios.post(uri, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "User-Uid": this._userUid,
          "Laravel-Session": this._laravelSession,
        },
      });
      return response?.data;
    } catch (e) {
      this._processError(url, (e as Error).message);
    }
  }

  // DELETE constructor
  async delete(url: string) {
    this.updateCookieSession();
    const uri = `${this._apiUrl}${url}`;
    try {
      const response: AxiosResponse = await axios.delete(uri, {
        headers: {
          "User-Uid": this._userUid,
          "Laravel-Session": this._laravelSession,
        },
      });
      return response?.data;
    } catch (e) {
      this._processError(url, (e as Error).message);
    }
  }

  // GET constructor base64
  async getBase64(url: string): Promise<any> {
    this.updateCookieSession();
    try {
      const response: AxiosResponse = await this._client.get(url, {
        responseType: "arraybuffer",
        headers: {
          "User-Uid": this._userUid,
          "Laravel-Session": this._laravelSession,
        },
      });
      return Buffer.from(response.data, "binary").toString("base64");
    } catch (e) {
      this._processError(url, (e as Error).message);
    }
  }

  private _processError(url: string, error: string): void {
    console.log(url);
    console.log("Exception: ", error);
  }
}

export default $HttpClient.getInstance();
