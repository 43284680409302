import { call, put, select } from "redux-saga/effects";
import { analAction } from "@store/actions";
import { IAnalyticAddLine } from "@store/types/anal/Interfaces.ts";
import { Constants } from "@configurations";
import { SendAnalyticDataSaga } from "./SendAnalyticDataSaga";

export function* AnalyticAddLineSaga(action: IAnalyticAddLine): any {
  const { routeName, eventType } = action;
  const state = yield select();
  const recoilState = state?.anal?.data;
  const recoilConfig = state?.anal?.config;
  const oldCurrentSession = state?.anal?.currentSession;

  try {
    // analytics check/send
    if (
      recoilConfig?.sessionId !== oldCurrentSession &&
      recoilState?.length !== 0
    ) {
      recoilState[recoilState?.length - 1].exitTimeMSeconds =
        localStorage.getItem("endRecordTime") || Date.now();
      recoilState[recoilState?.length - 1].spentTimeMSeconds =
        recoilState[recoilState?.length - 1].exitTimeMSeconds -
        recoilState[recoilState?.length - 1].enterTimeMSeconds;
      yield call(SendAnalyticDataSaga);
    }

    switch (eventType) {
      case "change-navigate": {
        if (recoilState?.length > 0) {
          recoilState[recoilState?.length - 1].exitTimeMSeconds = Date.now();
          recoilState[recoilState?.length - 1].spentTimeMSeconds =
            recoilState[recoilState?.length - 1].exitTimeMSeconds -
            recoilState[recoilState?.length - 1].enterTimeMSeconds;
        }
        break;
      }
      default: {
        break;
      }
    }

    // send analytic data, what else could it be
    if (recoilState?.length >= Constants.MAX_ANALYTICS_ITEMS_LENGTH) {
      yield put(analAction.sendAnalyticData());
    }

    const stateUpdated = yield select();
    const recoilStateUpdate = stateUpdated?.anal?.data;

    const newRecoilState = recoilStateUpdate.concat({
      routeName: routeName,
      enterTimeMSeconds: Date.now(),
      eventType: eventType,
      index: recoilStateUpdate?.length,
    });

    // add new line
    if (routeName) {
      yield put(analAction.setAnalyticLine(newRecoilState));
    }
  } catch (_) {
    console.log("Can't add event");
  }
}
