import { app } from "@store/types";

const initialState: app.IInitialState = {};

const appReducer = (state = initialState, action: app.IAction) => {
  switch (action.type) {
    case app.ActionTypes.APP_IS_READY: {
      return {
        ...state,
        appIsReady: true,
      };
    }
    case app.ActionTypes.SET_IS_WEB_SCREEN: {
      return {
        ...state,
        isWeb: action.isWeb,
      };
    }
    case app.ActionTypes.SET_EXTERNAL_DATA_READY: {
      return {
        ...state,
        isSetExternal: true,
      };
    }
    case app.ActionTypes.SET_COOKIE_DATA: {
      return {
        ...state,
        userUid: action.userUid,
        laravelSession: action.laravelSession,
      };
    }
    case app.ActionTypes.IMPLEMENT_UTM_ARRAY: {
      return {
        ...state,
        utmArray: action.utmArray,
      };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
