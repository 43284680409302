import "./MLocation.css";
import { LocationItem, MSubTitle } from "@components";
import { IShiftDetailsItem } from "@store/types/shiftDetails/Interfaces.ts";
import { calcAdditionalAddresses, countForm, ToastCall } from "@utils";
import { useNavigate } from "react-router-dom";
import { RouteEnum, routeParser } from "@navigators";
import { useMemo } from "react";

export interface IMLocationProps {
  data?: IShiftDetailsItem["multi_offices_data"]["list"];
  shiftId: string;
}

const MLocation = (props: IMLocationProps) => {
  const { data, shiftId } = props;
  const extraLength = calcAdditionalAddresses(data?.length);
  const navigate = useNavigate();

  const handleCopy = (id: string) => {
    navigator.clipboard.writeText(id).then(
      () => ToastCall.successCopy("Адрес смены скопирован"),
      () => {
        ToastCall.successCopy("Адрес смены скопирован");
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        KweekNative?.copyToClipboard(id);
      }
    );
  };

  const handlePress = (id: string) => {
    const mainShiftAddress = routeParser(
      RouteEnum.SHIFTS_MAP_MOBILE_DETAILS,
      shiftId
    );
    navigate(routeParser(mainShiftAddress, id, ":addressId"));
  };

  const renderLocationItem = () => {
    return data?.slice(0, 3)?.map((item, index) => {
      return (
        <LocationItem
          key={item?.uid}
          index={index}
          data={item}
          onCopyPress={handleCopy}
          onPress={handlePress}
        />
      );
    });
  };

  const handleViewMorePress = () => {
    navigate(routeParser(RouteEnum.SHIFTS_MAP_DETAILS, shiftId));
  };

  const memoLocationItem = useMemo(renderLocationItem, []);

  return (
    <div className="m-location-container">
      <MSubTitle title="Расположение" />
      {memoLocationItem}
      {extraLength && (
        <button
          className="m-location-extra-btn-box"
          onClick={handleViewMorePress}
        >
          <p className="m-location-extra-btn-text">{`Посмотреть +${extraLength} ${countForm(
            extraLength,
            ["адрес", "адреса", "адресов"]
          )} на карте`}</p>
        </button>
      )}
    </div>
  );
};

export default MLocation;
