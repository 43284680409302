import $HttpClient from "@client";

const CATEGORY_RESOURCES = {
  CATEGORY: "api/v1/web/shifts-categories",
};

const getCategory = async () => {
  return $HttpClient.get(CATEGORY_RESOURCES.CATEGORY);
};

export { getCategory };
