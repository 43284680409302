import "./MSchedule.css";

export interface IMScheduleProps {
  schedule: string;
}

const MSchedule = (props: IMScheduleProps) => {
  const { schedule } = props;

  return (
    <>
      <div className="m-schedule-line" />
      <div className="m-schedule-container">
        <p className="m-schedule-schedule-title">График</p>
        <div className="m-schedule-delimiter" />
        <p className="m-schedule-schedule-answer">{schedule}</p>
      </div>
      <div className="m-schedule-line" />
    </>
  );
};

export default MSchedule;
