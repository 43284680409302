import "./ShiftListItem.css";
import { IShiftListItem } from "@store/types/shifts/Interfaces.ts";
import { calculatePrice } from "@utils";
import { ImageRoom, Tags } from "@components";

export interface IShiftListItemProps extends IShiftListItem {
  onPress: (id: string) => void;
  isWeb?: boolean;
}

const ShiftListItem = (props: IShiftListItemProps) => {
  const {
    onPress,
    uid,
    office,
    profession,
    timetable_hum,
    price_month,
    isWeb,
    tags,
  } = props;

  const handlePress = () => {
    onPress?.(uid);
  };

  return (
    <button onClick={handlePress} className="shift-list-item-btn">
      <Tags tags={tags} />
      <div className="shift-list-item-container">
        <div className="shift-list-item-top">
          <ImageRoom
            className="shift-list-item-img"
            alt="office logo"
            src={office?.logo?.url}
          />
          <div className="shift-list-item-top-subbox">
            <p className="shift-list-item-title-text">
              {office?.office_group_title}
            </p>
            <p
              className={
                isWeb
                  ? "shift-list-item-profession-text"
                  : "m-shift-list-item-profession-text"
              }
            >
              {profession}
            </p>
          </div>
        </div>

        <div className="shift-list-item-line" />

        <div className="shift-list-item-bottom">
          <p
            className={
              isWeb
                ? "shift-list-item-price-text"
                : "m-shift-list-item-price-text"
            }
          >
            {calculatePrice(price_month)}
          </p>

          <div className="shift-list-item-timetable-box">
            <p className="shift-list-item-timetable-left-text">График</p>
            <div className="shift-list-item-timetable-line" />
            <p className="shift-list-item-timetable-right-text">
              {timetable_hum}
            </p>
          </div>
        </div>
      </div>
    </button>
  );
};

export default ShiftListItem;
