import { takeLatest, all } from "redux-saga/effects";
import {
  app,
  category,
  shifts,
  shiftDetails,
  map,
  anal,
  companyDetails,
} from "@store/types";
import { startup } from "./StartupSaga";
import { CategoryRequestSaga } from "./CategoryRequestSaga";
import { ShiftsRequestSaga } from "./ShiftsRequestSaga";
import { ChoseCategorySaga } from "./ChoseCategory";
import { GetShiftByIdSaga } from "./GetShiftByIdSaga";
import { GetMarkersSaga } from "./GetMarkersSaga";
import { updateExternalDataSaga } from "./UpdateExternalDataSaga";
import { AnalyticAddLineSaga } from "./AnalyticAddLineSaga";
import { SendAnalyticDataSaga } from "./SendAnalyticDataSaga";
import { GetCompanyDetailsSaga } from "./GetCompanyDetailsSaga.ts";
import { GetCompanyShiftsSaga } from "./GetCompanyShiftsSaga.ts";

/**
 * @description initialize root saga
 */
export default function* root(): any {
  yield all([takeLatest(app.ActionTypes.STARTUP, startup)]);
  yield all([
    takeLatest(category.ActionTypes.CATEGORY_REQUEST, CategoryRequestSaga),
  ]);
  yield all([takeLatest(shifts.ActionTypes.SHIFTS_REQUEST, ShiftsRequestSaga)]);
  yield all([
    takeLatest(category.ActionTypes.CHOSE_CATEGORY, ChoseCategorySaga),
  ]);
  yield all([
    takeLatest(shiftDetails.ActionTypes.GET_SHIFT_BY_ID, GetShiftByIdSaga),
  ]);
  yield all([takeLatest(map.ActionTypes.GET_MARKERS, GetMarkersSaga)]);
  yield all([
    takeLatest(app.ActionTypes.UPDATE_EXTERNAL_DATA, updateExternalDataSaga),
  ]);
  yield all([
    takeLatest(anal.ActionTypes.ANALYTIC_ADD_LINE, AnalyticAddLineSaga),
  ]);
  yield all([
    takeLatest(anal.ActionTypes.SEND_ANALYTIC_DATA, SendAnalyticDataSaga),
  ]);
  yield all([
    takeLatest(
      companyDetails.ActionTypes.GET_COMPANY_BY_ID,
      GetCompanyDetailsSaga
    ),
  ]);
  yield all([
    takeLatest(
      companyDetails.ActionTypes.COMPANY_SHIFTS_REQUEST,
      GetCompanyShiftsSaga
    ),
  ]);
}

export type RootSaga = ReturnType<typeof root>;
