import "./MAboutCompany.css";
import MultipleAbout from "@assets/multipleAbout.svg";
import parse from "html-react-parser";
import { useEffect, useRef } from "react";

export interface IMAboutCompanyProps {
  description: string;
  descTitle?: string;
  isOpenAlready?: boolean;
}
const MAboutCompany = (props: IMAboutCompanyProps) => {
  const {
    description,
    descTitle = "О компании",
    isOpenAlready = false,
  } = props;
  const accordion = useRef<any>(null);

  const handlePress = () => {
    accordion.current && accordion.current.removeAttribute("open");
  };

  useEffect(() => {
    if (isOpenAlready) {
      accordion.current && accordion.current.setAttribute("open", "true");
    }
  }, []);

  return (
    <details ref={accordion} className="m-about-company-container">
      <summary>
        <img
          src={MultipleAbout}
          alt="company logo"
          className="m-about-company-img"
        />
        <span className="m-about-company-description">{descTitle}</span>
      </summary>
      <>
        {parse(description)}
        <button className="m-about-company-close-btn" onClick={handlePress}>
          <p className="m-about-company-close-title">Свернуть описание</p>
        </button>
      </>
    </details>
  );
};

export default MAboutCompany;
