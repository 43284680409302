import { map } from "@store/types";
import { IMapMarkersItem } from "@store/types/map/Interfaces.ts";

export function getMarkers(): map.IAction {
  return {
    type: map.ActionTypes.GET_MARKERS,
  };
}

export function onMarkersSuccess(list: Array<IMapMarkersItem>): map.IAction {
  return {
    type: map.ActionTypes.ON_MARKERS_SUCCESS,
    list,
  };
}

export function onMarkersError(): map.IAction {
  return {
    type: map.ActionTypes.ON_MARKERS_ERROR,
  };
}
