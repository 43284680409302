import "./MShifts.css";
import { ITypesProps } from "@pages/Shifts/Types.ts";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RouteEnum, routeParser } from "@navigators";
import { CategoryContainer, MHeader, ShiftsList, OnMap } from "@components";
import { sliceCategoriesUrl } from "@utils";

const MShifts = (props: ITypesProps) => {
  const {
    isLoadingCategory,
    listShifts,
    isLoadingShifts,
    shiftsRequest,
    listCategory,
    categoryRequest,
    choseCategory,
    activeCategory,
    clearShiftDetails,
  } = props;

  useEffect(() => {
    !listCategory?.length && categoryRequest?.();
    !listShifts?.length && shiftsRequest?.(activeCategory);
    clearShiftDetails?.();
  }, []);
  const navigate = useNavigate();
  const handleShiftPress = (id: string) => {
    navigate(routeParser(RouteEnum.SHIFTS_DETAILS, id));
  };

  useEffect(() => {
    if (activeCategory) {
      history.pushState(null, "", sliceCategoriesUrl(activeCategory));
    }
  }, [activeCategory]);

  return (
    <>
      <MHeader />
      <div className="m-shifts-subheaderwithmap-container">
        <CategoryContainer
          isLoading={isLoadingCategory}
          list={listCategory}
          choseCategory={choseCategory}
          activeCategory={activeCategory}
          isWeb={false}
        />
        <ShiftsList
          isLoading={isLoadingShifts}
          list={listShifts}
          onPress={handleShiftPress}
          isWeb={false}
        />
      </div>

      {Boolean(listShifts?.length) && !isLoadingShifts && (
        <div className="m-shifts-subheaderwithmap-badge">
          <OnMap isWeb={false} />
        </div>
      )}
    </>
  );
};

export default MShifts;
