export const enum RouteEnum {
  STAR = "*",
  SLASH = "/",
  SHIFTS = "/shifts",
  SHIFTS_DETAILS = "/shifts/:id",
  SHIFTS_MAP = "/shifts/map",
  SHIFTS_MAP_MOBILE_DETAILS = "/shifts/:id/map/:addressId",
  SHIFTS_MAP_DETAILS = "/shifts/:id/map",
  NOT_FOUND = "/not_found",
  COMPANY_DETAILS = "/company/:id",
}
