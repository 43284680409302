import $HttpClient from "@client";

const SHIFTS_RESOURCES = {
  LIST: "api/v1/web/shifts",
  SHIFT_BY_ID: (id: string) => `api/v1/web/shifts/${id}`,
};

const getShifts = async <T>(data: T) => {
  return $HttpClient.get(SHIFTS_RESOURCES.LIST, data);
};

const getShiftById = async (id: string) => {
  return $HttpClient.get(SHIFTS_RESOURCES.SHIFT_BY_ID(id));
};

export { getShifts, getShiftById };
