import $HttpClient from "@client";

const COMPANY_RESOURCES = {
  COMPANY_BY_ID: (id: string) => `api/v1/web/offices/${id}`,
};

const getCompanyById = async (id: string) => {
  return $HttpClient.get(COMPANY_RESOURCES.COMPANY_BY_ID(id));
};

export { getCompanyById };
