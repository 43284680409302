import "./MEmptyList.css";
import NLO from "@assets/nlo.svg";
import { useNavigate } from "react-router-dom";

const MEmptyList = () => {
  const navigate = useNavigate();

  const handleBackPress = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="m-empty-main-box">
        <img src={NLO} alt="nlo" className="m-empty-img" />
        <p className="m-empty-text">
          Пока что здесь нет ни одного предложения. Попробуйте зайти позже -
          новые вакансии появляются каждый день!
        </p>
      </div>

      <div onClick={handleBackPress} className="shift-button-group-btn">
        <p className="shift-button-group-title">На главную</p>
      </div>
    </>
  );
};

export default MEmptyList;
