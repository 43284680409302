import "./CategoryContainer.css";
import { ICategoryListItem } from "@store/types/category/Interfaces.ts";
import { CategoryItem, Loader, CircleArrow } from "@components";
import { useEffect, useMemo, useRef, useState } from "react";

export interface ICategoryContainer {
  isLoading?: boolean;
  list?: Array<ICategoryListItem>;
  choseCategory?: (choseCategory: number) => void;
  activeCategory?: Array<number>;
  isWeb?: boolean;
}

const CategoryContainer = (props: ICategoryContainer) => {
  const { isLoading, list, choseCategory, activeCategory, isWeb } = props;
  const container = useRef<any>(null);
  const [isBtnVisible, setIsBtnVisible] = useState<boolean>(false);

  const calcPosition = () => {
    if (
      container.current?.scrollWidth - container.current?.scrollLeft >
      container.current?.offsetWidth
    ) {
      setIsBtnVisible(true);
    } else {
      setIsBtnVisible(false);
    }
  };

  useEffect(() => {
    calcPosition();
    container.current?.addEventListener("scroll", calcPosition);
    window.addEventListener("resize", calcPosition);

    return () => {
      container.current?.removeEventListener("scroll", calcPosition);
      window.removeEventListener("resize", calcPosition);
    };
  }, [container.current, isLoading]);

  const renderItems = () => {
    return list?.map((item) => {
      return (
        item?.count > 0 && (
          <CategoryItem
            key={item?.id}
            id={item?.id}
            count={item?.count}
            title={item?.title}
            url={item?.url}
            onPress={choseCategory}
            isActive={activeCategory?.includes(item?.id)}
          />
        )
      );
    });
  };
  const memoItems = useMemo(renderItems, [isLoading, activeCategory]);

  if (isLoading) {
    return (
      <div className="category-box-container-loader">
        <Loader />
      </div>
    );
  }

  const handleCircleArrowBtnPress = () => {
    container.current?.scroll({
      behavior: "smooth",
      left: container.current?.scrollWidth,
    });
  };

  return (
    <div
      ref={container}
      className={isWeb ? "category-box-container" : "m-category-box-container"}
    >
      {memoItems}
      {isBtnVisible && isWeb && (
        <div className="category-box-arrow">
          <CircleArrow onPress={handleCircleArrowBtnPress} />
        </div>
      )}
    </div>
  );
};

export default CategoryContainer;
