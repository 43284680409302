import "./OnMap.css";
import Map from "@assets/map.svg";
import { useNavigate } from "react-router-dom";
import { RouteEnum } from "@navigators";

interface IOnMapProps {
  isWeb?: boolean;
}

const OnMap = (props: IOnMapProps) => {
  const { isWeb } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(RouteEnum.SHIFTS_MAP);
  };

  return (
    <button
      onClick={handleClick}
      className={isWeb ? "on-map-container" : "m-on-map-container"}
    >
      <p className={isWeb ? "on-map-title" : "m-on-map-title"}>На карте</p>
      <img src={Map} alt="Map image" className="on-map-img" />
    </button>
  );
};

export default OnMap;
