import "./NotFound.css";
import CaretRightFilled from "@assets/caretRightFilled.svg";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-header-text">404. Страница не найдена.</h1>
      <div className="not-found-line" />
      <p className="not-found-description-text">
        Мы не смогли найти такую страницу. Возможно она устарела, была удалена
        или введен некорректный адрес в адресной строке
      </p>

      <div className="not-found-img-box">
        <img
          src={CaretRightFilled}
          alt="caret right filled"
          className="not-found-img"
        />
        <img
          src={CaretRightFilled}
          alt="caret right filled"
          className="not-found-img"
        />
        <img
          src={CaretRightFilled}
          alt="caret right filled"
          className="not-found-img"
        />
        <img
          src={CaretRightFilled}
          alt="caret right filled"
          className="not-found-img"
        />
      </div>
    </div>
  );
};

export default NotFound;
