/**
 * @param {number} addressCount
 * @return {boolean|number}
 */
const calcAdditionalAddresses = (addressCount = 3) => {
  const extraLength = addressCount - 3;

  if (extraLength > 0) {
    return extraLength;
  } else {
    return false;
  }
};

export { calcAdditionalAddresses };
