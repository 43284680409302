import "./MProfessionDesc.css";
import { calculatePrice } from "@utils";
import { ImageRoom } from "@components";
import { useNavigate } from "react-router-dom";
import { RouteEnum, routeParser } from "@navigators";

export interface IMProfessionDescProps {
  profession: string;
  priceMonth: number;
  companyName: string;
  companyLogo: string;
  companyId: string;
}

const MProfessionDesc = (props: IMProfessionDescProps) => {
  const { profession, companyLogo, companyName, priceMonth, companyId } = props;
  const navigate = useNavigate();

  return (
    <div className="m-profession-desc-container">
      <p className="m-profession-desc-profession">{profession}</p>
      <p className="m-profession-desc-price">{calculatePrice(priceMonth)}</p>

      <div
        onClick={() =>
          navigate(routeParser(RouteEnum.COMPANY_DETAILS, companyId))
        }
        className="m-profession-desc-company-box"
      >
        <ImageRoom
          src={companyLogo}
          alt="company logo"
          className="m-profession-desc-company-img"
        />
        <p className="m-profession-desc-company-name">{companyName}</p>
      </div>
    </div>
  );
};

export default MProfessionDesc;
