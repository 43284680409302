import { companyDetails } from "@store/types";

const initialState: companyDetails.IInitialState = {};

const companyDetailsReducer = (
  state = initialState,
  action: companyDetails.IAction
) => {
  switch (action.type) {
    case companyDetails.ActionTypes.GET_COMPANY_BY_ID: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case companyDetails.ActionTypes.ON_COMPANY_BY_ID_SUCCESS: {
      const oldState = { ...state };
      delete oldState.isLoading;
      return {
        ...oldState,
        data: action.data,
      };
    }
    case companyDetails.ActionTypes.COMPANY_BY_ID_ERROR: {
      const oldState = { ...state };
      delete oldState.isLoading;
      return {
        ...oldState,
      };
    }
    case companyDetails.ActionTypes.CLEAR_COMPANY_DETAILS: {
      return {};
    }
    case companyDetails.ActionTypes.COMPANY_SHIFTS_REQUEST: {
      return {
        ...state,
        isShiftLoading: true,
      };
    }
    case companyDetails.ActionTypes.ON_COMPANY_SHIFTS_SUCCESS: {
      const oldState = { ...state };
      delete oldState.isShiftLoading;
      return {
        ...oldState,
        list: action.list,
      };
    }
    case companyDetails.ActionTypes.ON_COMPANY_SHIFTS_ERROR: {
      const oldState = { ...state };
      delete oldState.isShiftLoading;
      return {
        ...oldState,
      };
    }
    default: {
      return state;
    }
  }
};

export default companyDetailsReducer;
