import { call, put } from "redux-saga/effects";
import { appAction } from "@store/actions";
import { IUpdateExternalData } from "@store/types/app/Interfaces.ts";
import { ExternalDataService } from "@services";

export function* updateExternalDataSaga(action: IUpdateExternalData): any {
  const { utmSource, utmCampaign } = action;
  yield put(appAction.setExternalDataReady());
  const tData: {
    link: string;
    utm_source?: string;
    utm_campaign?: string;
  } = {
    link: window.location.href,
  };

  if (utmSource !== null) {
    tData.utm_source = utmSource;
  }
  if (utmCampaign !== null) {
    tData.utm_campaign = utmCampaign;
  }

  yield call(ExternalDataService.postExternalAnalytics, tData);
}
