import "./Wait.css";
import { Loader } from "@components";

const Wait = () => {
  return (
    <div className="loader-container">
      <Loader />
    </div>
  );
};

export default Wait;
