import { useNavigate } from "react-router-dom";
import { RouteEnum } from "@navigators";
import { useEffect } from "react";

const StarWayRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isError = window.location.pathname !== RouteEnum.SLASH;
    switch (isError) {
      case true: {
        navigate(RouteEnum.NOT_FOUND);
        break;
      }
      default: {
        navigate(RouteEnum.SHIFTS);
        break;
      }
    }
  }, []);

  return null;
};

export default StarWayRoute;
