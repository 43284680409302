import { put, call } from "redux-saga/effects";
import { shiftsAction } from "@store/actions";
import { ShiftsService } from "@services";
import { IShiftsRequest } from "@store/types/shifts/Interfaces.ts";
import { Constants } from "@configurations";

export function* ShiftsRequestSaga(action: IShiftsRequest): any {
  const { activeCategory, addresses } = action;
  try {
    const data = genFilter(activeCategory, addresses);
    const res = yield call(ShiftsService.getShifts, data);
    if (res.data?.items?.length > 0) {
      if (addresses) {
        yield put(
          shiftsAction.onPositionShiftsSuccess(res.data.items, addresses)
        );
      } else {
        yield put(
          shiftsAction.onShiftsSuccess(
            res.data.items,
            res.data?.total,
            res.data?.per_page,
            res.data?.last_page,
            res.data?.current_page
          )
        );
        if (res.data?.current_page !== res.data?.last_page) {
          for (let i = 2; i <= res.data?.last_page; i++) {
            const data = genFilter(activeCategory, addresses, i);
            const resT = yield call(ShiftsService.getShifts, data);
            yield put(
              shiftsAction.onShiftsSuccessMore(
                resT.data.items,
                resT.data?.total,
                resT.data?.per_page,
                resT.data?.last_page,
                resT.data?.current_page
              )
            );
          }
        }
      }
    } else {
      yield put(shiftsAction.onShiftsError());
    }
  } catch (_) {
    yield put(shiftsAction.onShiftsError());
  }
}

const genFilter = (
  activeCategory?: number[],
  addresses?: string[],
  page = 1
) => {
  return {
    filter: {
      categories: activeCategory,
      addresses: addresses,
    },
    type_ex: "leadogeneration",
    per_page: Constants.LOAD_SHIFTS_PER_PAGE,
    page: page,
  };
};
