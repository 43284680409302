const Constants = {
  MAX_MOBILE_WIDTH: 640,
  MAX_MOBILE_HEIGHT: 640,
  LATITUDE_DEFAULT_REGION: 55.7522,
  LONGITUDE_DEFAULT_REGION: 37.6156,
  LOAD_SHIFTS_PER_PAGE: 10,
  UTM_SOURCE: "utm_source",
  UTM_CAMPAIGN: "utm_campaign",
  MAX_ANALYTICS_ITEMS_LENGTH: 10,
  UTM_FIND_STRING: "utm_",
  CATEGORIES_FIND_STRING: "categories",
};

export default Constants;
