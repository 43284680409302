import "./MShiftsMap.css";
import { ITypesProps } from "@pages/ShiftsMap/Types.ts";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Loader,
  MProfessionHeader,
  MapButton,
  MMapListButton,
  MPanel,
} from "@components";
import { Constants, Env } from "@configurations";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useLoadScript,
} from "@react-google-maps/api";
import { IShiftDetailsListItem } from "@store/types/shiftDetails/Interfaces.ts";
import { RouteEnum, routeParser } from "@navigators";
import DetailsMarkerActive from "@assets/detailsMarkerActive.svg";
import DetailsMarker from "@assets/detailsMarker.svg";
import EnlargedMarker from "@assets/enlargedMarker.svg";
import DefaultMarker from "@assets/defaultMarker.svg";
import { calculateLabelText } from "@pages/ShiftsMap/lib.ts";
import Plus from "@assets/plus.svg";
import Minus from "@assets/minus.svg";
import Position from "@assets/position.svg";
import { ClusterImage } from "@assets";
import MyPositionMarker from "@assets/MyPositionMarker.svg";
import { IMapMarkersItem } from "@store/types/map/Interfaces.ts";
import { countForm } from "@utils";

const MShiftsMap = (props: ITypesProps) => {
  const {
    isLoadingShifts,
    shiftsRequest,
    listShifts,
    getShiftById,
    isLoadingDetails,
    dataDetails,
    getMarkers,
    listMarkers,
    isLoadingMap,
    activeCategory,
    clearShiftDetails,
    addresses,
    positionShiftsClean,
    positionList,
  } = props;
  const container = useRef<any>(null);

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [map, setMap] = useState<any>(null);
  const { id, addressId } = useParams();
  const [markerTypes, setMarkerTypes] = useState<"all" | "details">("all");
  const [activeMarker, setActiveMarker] = useState<string>(addressId ?? "");
  const [userPosition, setUserPosition] = useState<{
    lat: number;
    lng: number;
  }>();

  useEffect(() => {
    if (container.current) {
      container.current?.scroll({
        behavior: "smooth",
        top: 25,
      });
    }
  }, [container]);

  useEffect(() => {
    if (!dataDetails && id) {
      getShiftById?.(id);
      setMarkerTypes("details");
    }
    dataDetails && setMarkerTypes("details");
    getMarkers?.();
  }, []);

  useEffect(() => {
    if (!dataDetails && id) {
      getShiftById?.(id);
      setMarkerTypes("details");
    }
  }, [id]);

  useEffect(() => {
    if (addressId && map) {
      setActiveMarker(addressId);
      const fillMarker = dataDetails?.multi_offices_data?.list?.filter(
        (v) => v.uid === addressId
      )?.[0];

      if (fillMarker) {
        map?.setZoom(15);
        map?.panTo({
          lat: Number(fillMarker?.lat),
          lng: Number(fillMarker?.lon),
        });
        setIsVisible(true);
      }
    }
  }, [addressId, dataDetails, map]);

  useEffect(() => {
    if (!id && !addressId) {
      setIsVisible(false);
      setMarkerTypes("all");
      clearShiftDetails?.();
    }
  }, [id, addressId]);

  const center = useMemo(
    () => ({
      lat: Constants.LATITUDE_DEFAULT_REGION,
      lng: Constants.LONGITUDE_DEFAULT_REGION,
    }),
    []
  );
  const [isEnlarged, setIsEnlarged] = useState<boolean>(false);
  const navigate = useNavigate();

  const onLoad = useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: Env.GOOGLE_MAPS_API_KEY,
  });

  const handlePressDetailsMarker = (v: IShiftDetailsListItem) => {
    setActiveMarker(v?.uid);
    const mainShiftAddress = routeParser(
      RouteEnum.SHIFTS_MAP_MOBILE_DETAILS,
      id!
    );
    navigate(routeParser(mainShiftAddress, v?.uid, ":addressId"), {
      replace: true,
    });
  };

  const renderDetailsMarker = (clusterer: any) => {
    return dataDetails?.multi_offices_data?.list?.map((v) => {
      return (
        <Marker
          clusterer={clusterer}
          key={`address_details_${v?.uid}`}
          position={{
            lat: Number(v?.lat),
            lng: Number(v?.lon),
          }}
          icon={v?.uid === activeMarker ? DetailsMarkerActive : DetailsMarker}
          onClick={() => handlePressDetailsMarker(v)}
        />
      );
    });
  };

  const handlePressMarker = (v: IMapMarkersItem) => {
    setIsVisible(true);
    shiftsRequest?.(activeCategory, v?.uids);
    map?.panTo({
      lat: Number(v?.latitude),
      lng: Number(v?.longitude),
    });
  };

  const handleCloseMarker = () => {
    setIsVisible(false);
    positionShiftsClean?.();
    setMarkerTypes("all");
    clearShiftDetails?.();
    setActiveMarker("");
  };

  const handleCloseDetailsMarker = () => {
    setIsVisible(false);
    navigate(routeParser(RouteEnum.SHIFTS_MAP_DETAILS, id!), {
      replace: true,
    });
    setActiveMarker("");
  };

  const renderMarkers = (clusterer: any) => {
    return listMarkers?.map((item) => {
      const isSingle = item?.shifts?.length === 1;
      if (isEnlarged) {
        return (
          <Marker
            key={`address_${item?.uids?.[0]}`}
            position={{
              lat: Number(item?.latitude),
              lng: Number(item?.longitude),
            }}
            icon={isSingle ? EnlargedMarker : DefaultMarker}
            clusterer={clusterer}
            label={
              isSingle
                ? {
                    text: calculateLabelText(
                      item?.shifts?.length,
                      item?.shifts?.[0]?.price_month
                    ),
                    className: "shift-google-map-enlarged-marker-label",
                  }
                : {
                    text: String(item?.shifts?.length),
                  }
            }
            onClick={() => handlePressMarker(item)}
          />
        );
      } else {
        return (
          <Marker
            key={`address_${item?.uids?.[0]}`}
            position={{
              lat: Number(item?.latitude),
              lng: Number(item?.longitude),
            }}
            icon={DefaultMarker}
            clusterer={clusterer}
            label={String(item?.shifts?.length)}
            onClick={() => handlePressMarker(item)}
          />
        );
      }
    });
  };

  const renderMemoMarkers = useCallback(
    (t: any) => {
      switch (markerTypes) {
        case "all": {
          return renderMarkers(t);
        }
        case "details": {
          return renderDetailsMarker(t);
        }
      }
    },
    [
      listMarkers,
      isEnlarged,
      dataDetails,
      markerTypes,
      isLoadingDetails,
      activeMarker,
    ]
  );

  const handlePlusZoom = () => {
    map.setZoom(map.zoom + 1);
  };

  const handleMinusZoom = () => {
    map.setZoom(map.zoom - 1);
  };

  const initPosition = () => {
    navigator.geolocation.getCurrentPosition((e) => {
      setUserPosition({
        lat: e?.coords?.latitude,
        lng: e?.coords?.longitude,
      });
      map?.setZoom(15);
      map?.panTo({
        lat: e?.coords?.latitude,
        lng: e?.coords?.longitude,
      });
    });
  };

  const handleCloseMap = () => {
    navigate(RouteEnum.SHIFTS, { replace: true });
  };

  const handleMoreBtn = () => {
    setIsVisible(true);
  };

  const calcType = () => {
    if (isLoadingShifts || isLoadingDetails) {
      return "loading";
    }
    if (addressId) {
      return "extraDetail";
    }

    return dataDetails?.profession ? "detail" : "map";
  };

  const handleShiftPress = (id: string) => {
    navigate(routeParser(RouteEnum.SHIFTS_DETAILS, id));
  };

  if (!isLoaded) {
    return (
      <div className="shifts-map-container-loader">
        <Loader />
      </div>
    );
  }

  const calcMoreBtnTitle = () => {
    return `${dataDetails?.multi_offices_data?.list?.length} ${countForm(
      dataDetails?.multi_offices_data?.list?.length ?? 1,
      ["адрес", "адреса", "адресов"]
    )} списком`;
  };

  return (
    <div className="m-shifts-map-container">
      {isLoadingMap && (
        <div className="m-shifts-google-map-loader">
          <Loader />
        </div>
      )}
      {dataDetails && (
        <div className="m-shifts-map-profession-header">
          <MProfessionHeader data={dataDetails} />
        </div>
      )}

      <div className="m-shifts-map-backdrop-zoom-btns">
        <MapButton icon={Plus} onPress={handlePlusZoom} />
        <MapButton icon={Minus} onPress={handleMinusZoom} />
      </div>
      <div ref={container} className="m-shifts-map-backdrop-position">
        <MapButton icon={Position} onPress={initPosition} />
      </div>

      <div className="m-shifts-map-panel">
        <MPanel
          type={calcType()}
          isVisible={isVisible}
          closeModal={
            dataDetails?.profession
              ? handleCloseDetailsMarker
              : handleCloseMarker
          }
          listShifts={listShifts}
          isLoadingShifts={isLoadingShifts}
          handleShiftPress={handleShiftPress}
          positionList={positionList}
          id={id}
          data={dataDetails}
          addresses={addresses}
          addressId={addressId}
        />
      </div>

      {!id ? (
        <div className="m-shifts-map-list-button">
          <MMapListButton onPress={handleCloseMap} />
        </div>
      ) : (
        <div className="m-shifts-map-list-button">
          <MMapListButton title={calcMoreBtnTitle()} onPress={handleMoreBtn} />
        </div>
      )}

      <GoogleMap
        mapContainerClassName="m-shifts-map-main-container"
        center={center}
        zoom={10}
        clickableIcons={false}
        onLoad={onLoad}
        onClick={() => {
          if (activeMarker) {
            setActiveMarker("");
            setIsVisible(false);
            navigate(routeParser(RouteEnum.SHIFTS_MAP_DETAILS, id!));
          }
          setIsVisible(false);
        }}
        onUnmount={onUnmount}
        onZoomChanged={() => map?.zoom && setIsEnlarged(map?.zoom >= 13)}
        options={{
          mapId: Env.MAP_ID,
          disableDefaultUI: true,
        }}
      >
        <MarkerClusterer
          styles={[
            {
              url: ClusterImage,
              height: 40,
              width: 40,
            },
          ]}
          calculator={(markers, num) => {
            switch (markerTypes) {
              case "details": {
                return {
                  text: String(markers?.length),
                  index: num,
                };
              }
              case "all": {
                let shiftsCount = 0;
                markers.forEach((item: any) => {
                  if (!isEnlarged) {
                    const label =
                      typeof Number(item.getLabel()) === "number" &&
                      !isNaN(Number(item.getLabel()))
                        ? Number(item.getLabel())
                        : 0;
                    shiftsCount += Number(label);
                  } else {
                    const label = item.getLabel()?.text?.trim()?.slice(0, 1);
                    shiftsCount += Number(label);
                  }
                });
                return {
                  text: String(shiftsCount),
                  index: num,
                };
              }
            }
          }}
          averageCenter
        >
          {(clusterer) => (
            <>
              {userPosition && (
                <Marker position={userPosition} icon={MyPositionMarker} />
              )}
              {renderMemoMarkers(clusterer)}
            </>
          )}
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
};

export default MShiftsMap;
