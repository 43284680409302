import "./MSubTitle.css";

export interface IMSubTitle {
  title?: string;
}

const MSubTitle = (props: IMSubTitle) => {
  const { title = "" } = props;

  return <p className="m-subtitle-text">{title?.toUpperCase()}</p>;
};

export default MSubTitle;
