import "./EmptyList.css";
import NLO from "@assets/nlo.svg";

const EmptyList = () => {
  return (
    <div className="empty-container">
      <div className="empty-main-box">
        <img src={NLO} alt="nlo" className="empty-img" />
        <p className="empty-text">
          Пока что здесь нет ни одного предложения. Попробуйте зайти позже -
          новые вакансии появляются каждый день!
        </p>
      </div>
    </div>
  );
};

export default EmptyList;
