import "./ShiftsMap.css";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  MarkerClusterer,
} from "@react-google-maps/api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Constants, Env } from "@configurations";
import {
  CloseMap,
  Header,
  Loader,
  MapButton,
  Panel,
  LocationItem,
} from "@components";
import Plus from "@assets/plus.svg";
import Minus from "@assets/minus.svg";
import Position from "@assets/position.svg";
import { useNavigate, useParams } from "react-router-dom";
import { RouteEnum, routeParser } from "@navigators";
import { ITypesProps } from "./Types.ts";
import DefaultMarker from "@assets/defaultMarker.svg";
import EnlargedMarker from "@assets/enlargedMarker.svg";
import { calculateLabelText } from "./lib.ts";
import { ClusterImage } from "@assets";
import DetailsMarker from "@assets/detailsMarker.svg";
import DetailsMarkerActive from "@assets/detailsMarkerActive.svg";
import { IShiftDetailsListItem } from "@store/types/shiftDetails/Interfaces.ts";
import { ToastCall, ToastPosition } from "@utils";
import Close from "@assets/Close.svg";
import MyPositionMarker from "@assets/MyPositionMarker.svg";

const ShiftsMap = (props: ITypesProps) => {
  const {
    isLoadingShifts,
    shiftsRequest,
    listShifts,
    getShiftById,
    isLoadingDetails,
    dataDetails,
    getMarkers,
    listMarkers,
    isLoadingMap,
    activeCategory,
    clearShiftDetails,
    addresses,
    positionShiftsClean,
    positionList,
    utmArray,
  } = props;

  const [map, setMap] = useState<any>(null);
  const { id, addressId } = useParams();
  const [markerTypes, setMarkerTypes] = useState<"all" | "details">("all");
  const [activeMarker, setActiveMarker] = useState<string>(addressId ?? "");
  const toastIds = useRef<number>();
  const [userPosition, setUserPosition] = useState<{
    lat: number;
    lng: number;
  }>();

  useEffect(() => {
    !listShifts?.length && shiftsRequest?.(activeCategory);
    if ((!dataDetails && id) || (dataDetails?.uid !== id && id)) {
      getShiftById?.(id);
      setMarkerTypes("details");
    }
    dataDetails && setMarkerTypes("details");
    getMarkers?.();
  }, []);

  const handleCopy = (id: string) => {
    navigator.clipboard.writeText(id).then((_) => {
      ToastCall.successCopy(
        "Адрес смены скопирован",
        ToastPosition.BOTTOM_LEFT,
        "shifts-map-copy-toast-position"
      );
    });
  };

  useEffect(() => {
    if (addressId && map) {
      setActiveMarker(addressId);
      const fillMarker = dataDetails?.multi_offices_data?.list?.filter(
        (v) => v.uid === addressId
      )?.[0];

      if (fillMarker) {
        map?.setZoom(15);
        map?.panTo({
          lat: Number(fillMarker?.lat),
          lng: Number(fillMarker?.lon),
        });

        if (!toastIds.current) {
          toastIds.current = ToastCall.customJSX(
            <div className="shifts-map-custom-toast-container">
              <LocationItem
                index={0}
                data={fillMarker}
                onCopyPress={handleCopy}
                onPress={() => {
                  ToastCall.clearCustomJSX();
                  setActiveMarker("");
                  navigate(routeParser(RouteEnum.SHIFTS_DETAILS, id!));
                }}
              />
              <div className="shifts-map-custom-toast-close-container">
                <img
                  src={Close}
                  alt="close button"
                  className="shifts-map-custom-toast-close-img"
                />
              </div>
            </div>,
            "toast-custom-jsx-location-item"
          );
        } else {
          ToastCall.clearCustomJSX();
          toastIds.current = ToastCall.customJSX(
            <div className="shifts-map-custom-toast-container">
              <LocationItem
                index={0}
                data={fillMarker}
                onCopyPress={handleCopy}
                onPress={() => {
                  ToastCall.clearCustomJSX();
                  setActiveMarker("");
                  navigate(routeParser(RouteEnum.SHIFTS_DETAILS, id!));
                }}
              />
              <div className="shifts-map-custom-toast-close-container">
                <img
                  src={Close}
                  alt="close button"
                  className="shifts-map-custom-toast-close-img"
                />
              </div>
            </div>,
            "toast-custom-jsx-location-item"
          );
        }
      }
    }
  }, [addressId, dataDetails, map]);

  const center = useMemo(
    () => ({
      lat: Constants.LATITUDE_DEFAULT_REGION,
      lng: Constants.LONGITUDE_DEFAULT_REGION,
    }),
    []
  );
  const [isEnlarged, setIsEnlarged] = useState<boolean>(false);
  const navigate = useNavigate();

  const onLoad = useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: Env.GOOGLE_MAPS_API_KEY,
  });

  const handlePressDetailsMarker = (v: IShiftDetailsListItem) => {
    setActiveMarker(v?.uid);
    const mainShiftAddress = routeParser(
      RouteEnum.SHIFTS_MAP_MOBILE_DETAILS,
      id!
    );
    navigate(routeParser(mainShiftAddress, v?.uid, ":addressId"));
  };

  const renderDetailsMarker = (clusterer: any) => {
    return dataDetails?.multi_offices_data?.list?.map((v) => {
      return (
        <Marker
          clusterer={clusterer}
          key={`address_details_${v?.uid}`}
          position={{
            lat: Number(v?.lat),
            lng: Number(v?.lon),
          }}
          icon={v?.uid === activeMarker ? DetailsMarkerActive : DetailsMarker}
          onClick={() => handlePressDetailsMarker(v)}
        />
      );
    });
  };

  const renderMarkers = (clusterer: any) => {
    return listMarkers?.map((item) => {
      const isSingle = item?.shifts?.length === 1;
      if (isEnlarged) {
        return (
          <Marker
            key={`address_${item?.uids?.[0]}`}
            position={{
              lat: Number(item?.latitude),
              lng: Number(item?.longitude),
            }}
            icon={isSingle ? EnlargedMarker : DefaultMarker}
            clusterer={clusterer}
            label={
              isSingle
                ? {
                    text: calculateLabelText(
                      item?.shifts?.length,
                      item?.shifts?.[0]?.price_month
                    ),
                    className: "shift-google-map-enlarged-marker-label",
                  }
                : {
                    text: String(item?.shifts?.length),
                  }
            }
            onClick={() => {
              navigate(RouteEnum.SHIFTS_MAP, { replace: true });
              shiftsRequest?.(activeCategory, item?.uids);
              map?.panTo({
                lat: Number(item?.latitude),
                lng: Number(item?.longitude),
              });
            }}
          />
        );
      } else {
        return (
          <Marker
            key={`address_${item?.uids?.[0]}`}
            position={{
              lat: Number(item?.latitude),
              lng: Number(item?.longitude),
            }}
            icon={DefaultMarker}
            clusterer={clusterer}
            label={String(item?.shifts?.length)}
            onClick={() => {
              navigate(RouteEnum.SHIFTS_MAP, { replace: true });
              shiftsRequest?.(activeCategory, item?.uids);
              map?.panTo({
                lat: Number(item?.latitude),
                lng: Number(item?.longitude),
              });
            }}
          />
        );
      }
    });
  };

  const renderMemoMarkers = useCallback(
    (t: any) => {
      switch (markerTypes) {
        case "all": {
          return renderMarkers(t);
        }
        case "details": {
          return renderDetailsMarker(t);
        }
      }
    },
    [
      listMarkers,
      isEnlarged,
      dataDetails,
      markerTypes,
      isLoadingDetails,
      activeMarker,
    ]
  );

  const handlePlusZoom = () => {
    map.setZoom(map.zoom + 1);
  };

  const handleMinusZoom = () => {
    map.setZoom(map.zoom - 1);
  };

  const initPosition = () => {
    navigator.geolocation.getCurrentPosition((e) => {
      setUserPosition({
        lat: e?.coords?.latitude,
        lng: e?.coords?.longitude,
      });
      map?.panTo({
        lat: e?.coords?.latitude,
        lng: e?.coords?.longitude,
      });
    });
  };

  const handleCloseMap = () => {
    positionShiftsClean?.();
    navigate(RouteEnum.SHIFTS, { replace: true });
  };

  const calcType = () => {
    if (isLoadingShifts || isLoadingDetails) {
      return "loading";
    }
    return id ? "detail" : "map";
  };

  const handleShiftPress = (id: string) => {
    getShiftById?.(id);
    setMarkerTypes("details");
    navigate(routeParser(RouteEnum.SHIFTS_DETAILS, id));
  };

  if (!isLoaded) {
    return (
      <div className="shifts-map-container-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="shifts-map-container">
      {(isLoadingMap || isLoadingDetails) && (
        <div className="shifts-google-map-loader">
          <Loader />
        </div>
      )}

      <Header isAbsolute />

      <div className="shifts-map-panel">
        <Panel
          utmArray={utmArray}
          type={calcType()}
          listShifts={listShifts}
          isLoadingShifts={isLoadingShifts}
          handleShiftPress={handleShiftPress}
          data={dataDetails}
          positionList={positionList}
          id={id}
          clearShift={() => {
            setMarkerTypes("all");
            clearShiftDetails?.();
            setActiveMarker("");
            ToastCall.clearCustomJSX();
          }}
          addresses={addresses}
          shiftsRequest={() => {
            setMarkerTypes("all");
            positionShiftsClean?.();
            setActiveMarker("");
            ToastCall.clearCustomJSX();
          }}
        />
      </div>
      {!id && (
        <div className="shifts-map-backdrop-close-btn">
          <CloseMap onPress={handleCloseMap} />
        </div>
      )}

      <div className="shifts-map-backdrop-zoom-btns">
        <MapButton icon={Plus} onPress={handlePlusZoom} />
        <MapButton icon={Minus} onPress={handleMinusZoom} />
      </div>
      <div className="shifts-map-backdrop-position">
        <MapButton icon={Position} onPress={initPosition} />
      </div>
      <GoogleMap
        mapContainerClassName="shifts-map-main-container"
        center={center}
        zoom={10}
        clickableIcons={false}
        onLoad={onLoad}
        onClick={() => {
          if (activeMarker) {
            navigate(routeParser(RouteEnum.SHIFTS_DETAILS, id!));
            ToastCall.clearCustomJSX();
            setActiveMarker("");
          }
        }}
        onUnmount={onUnmount}
        onZoomChanged={() => map?.zoom && setIsEnlarged(map?.zoom >= 13)}
        options={{
          mapId: Env.MAP_ID,
          disableDefaultUI: true,
        }}
      >
        <MarkerClusterer
          averageCenter
          calculator={(markers, num) => {
            switch (markerTypes) {
              case "all": {
                let shiftsCount = 0;
                markers.forEach((item: any) => {
                  if (!isEnlarged) {
                    const label =
                      typeof Number(item.getLabel()) === "number" &&
                      !isNaN(Number(item.getLabel()))
                        ? Number(item.getLabel())
                        : 0;
                    shiftsCount += Number(label);
                  } else {
                    const label = item.getLabel()?.text?.trim()?.slice(0, 1);
                    shiftsCount += Number(label);
                  }
                });
                return {
                  text: String(shiftsCount),
                  index: num,
                };
              }
              case "details": {
                return {
                  text: String(markers?.length),
                  index: num,
                };
              }
            }
          }}
          styles={[
            {
              url: ClusterImage,
              height: 40,
              width: 40,
            },
          ]}
        >
          {(clusterer) => (
            <>
              {userPosition && (
                <Marker position={userPosition} icon={MyPositionMarker} />
              )}
              {renderMemoMarkers(clusterer)}
            </>
          )}
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
};

export default ShiftsMap;
