import { app } from "@store/types";

export function startup(): app.IAction {
  return {
    type: app.ActionTypes.STARTUP,
  };
}

export function appIsReady(): app.IAction {
  return {
    type: app.ActionTypes.APP_IS_READY,
  };
}

export function setIsWebScreen(isWeb: boolean): app.IAction {
  return {
    type: app.ActionTypes.SET_IS_WEB_SCREEN,
    isWeb,
  };
}

export function updateExternalData(
  utmSource: string | null,
  utmCampaign: string | null
): app.IAction {
  return {
    type: app.ActionTypes.UPDATE_EXTERNAL_DATA,
    utmSource,
    utmCampaign,
  };
}

export function setExternalDataReady(): app.IAction {
  return {
    type: app.ActionTypes.SET_EXTERNAL_DATA_READY,
  };
}

export function setCookieData(
  userUid: string,
  laravelSession: string
): app.IAction {
  return {
    type: app.ActionTypes.SET_COOKIE_DATA,
    userUid,
    laravelSession,
  };
}

export function implementUtmArray(
  utmArray: Array<{ key?: string; value?: string }>
): app.IAction {
  return {
    type: app.ActionTypes.IMPLEMENT_UTM_ARRAY,
    utmArray,
  };
}
