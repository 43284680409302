import "./SubHeaderWithMap.css";
import { OnMap } from "@components";

const SubHeaderWithMap = () => {
  return (
    <div className="subheaderwithmap-container">
      <h2 className="subheaderwithmap-title">Работа</h2>
      <OnMap isWeb />
    </div>
  );
};

export default SubHeaderWithMap;
