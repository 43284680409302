import "./Tags.css";

export interface ITags {
  tags: Array<{
    color: string;
    tag: string;
  }>;
  absolute?: boolean;
}

const Tags = (props: ITags) => {
  const { tags, absolute = true } = props;

  const calcTagClassName = (type: "box" | "title", color: string) => {
    switch (type) {
      case "box": {
        return color === "violet" ? "tags-violet-box" : "tags-green-box";
      }
      default: {
        return color === "violet" ? "tags-violet-title" : "tags-green-title";
      }
    }
  };

  const renderTags = () => {
    return tags?.map((item, index) => {
      return (
        <div
          key={index}
          className={`tag-item-box ${calcTagClassName("box", item?.color)}`}
        >
          <p
            className={`tag-item-title ${calcTagClassName(
              "title",
              item?.color
            )}`}
          >
            {item?.tag}
          </p>
        </div>
      );
    });
  };

  return (
    tags?.length > 0 && (
      <div className={absolute ? "tags-container" : "tags-container-external"}>
        {renderTags()}
      </div>
    )
  );
};

export default Tags;
