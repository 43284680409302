import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Constants } from "@configurations";
import { appAction, analAction } from "@store/actions";

export interface IExternalController {
  content: JSX.Element;
}

const ExternalController = (props: IExternalController) => {
  const { content } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(
      analAction.analyticAddLine(window.location.href, "change-navigate")
    );
  }, [window.location.href]);

  const dispatch = useDispatch();

  useEffect(() => {
    const utmSourceInUrl = searchParams.get(Constants.UTM_SOURCE);
    const utmCampaignInUrl = searchParams.get(Constants.UTM_CAMPAIGN);
    if (utmSourceInUrl || utmCampaignInUrl) {
      dispatch(appAction.updateExternalData(utmSourceInUrl, utmCampaignInUrl));
    }
  }, []);

  const updateNavigationState = () => {
    navigate?.(-1);
  };

  useEffect(() => {
    window.addEventListener("MobileNativeBackButton", updateNavigationState);

    return () =>
      window.removeEventListener(
        "MobileNativeBackButton",
        updateNavigationState
      );
  }, []);

  return <>{content}</>;
};

export default ExternalController;
