import { anal } from "@store/types";
import { nanoid } from "nanoid";
import { Constants } from "@configurations";

const initialState: anal.IInitialState = {
  config: {
    sessionId: nanoid(),
    maxItems: Constants.MAX_ANALYTICS_ITEMS_LENGTH,
    startRecord: Date.now(),
  },
  currentSession: nanoid(),
  currentStartRecord: Date.now(),
  data: [],
};

const analReducer = (state = initialState, action: anal.IAction) => {
  switch (action.type) {
    case anal.ActionTypes.SET_ANALYTIC_LINE: {
      return {
        ...state,
        data: action.data,
      };
    }
    case anal.ActionTypes.CLEAR_ANALYTIC_DATA: {
      return {
        ...state,
        data: [],
        currentSession: state?.config?.sessionId,
        currentStartRecord: state?.config?.startRecord,
      };
    }
    default: {
      return state;
    }
  }
};

export default analReducer;
