import { shifts } from "@store/types";

const initialState: shifts.IInitialState = {};

const shiftsReducer = (state = initialState, action: shifts.IAction) => {
  switch (action.type) {
    case shifts.ActionTypes.SHIFTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case shifts.ActionTypes.ON_SHIFTS_SUCCESS: {
      const oldState = { ...state };
      delete oldState.isLoading;
      return {
        ...oldState,
        list: action.list,
        currentPage: action.currentPage,
        lastPage: action.lastPage,
        total: action.total,
        per_page: action.per_page,
      };
    }
    case shifts.ActionTypes.ON_SHIFTS_ERROR: {
      const oldState = { ...state };
      delete oldState.isLoading;
      return {
        ...oldState,
      };
    }
    // position
    case shifts.ActionTypes.ON_POSITION_SHIFTS_SUCCESS: {
      const oldState = { ...state };
      delete oldState.isLoading;
      return {
        ...oldState,
        positionList: action.positionList,
        addresses: action.addresses,
      };
    }
    case shifts.ActionTypes.POSITION_SHIFTS_CLEAN: {
      const oldState = { ...state };
      delete oldState.positionList;
      delete oldState.addresses;
      return {
        ...oldState,
      };
    }
    // more shifts
    case shifts.ActionTypes.ON_SHIFTS_SUCCESS_MORE: {
      const oldState = { ...state };
      const oldList = [...(state?.list || [])];
      const newListData = action?.list || [];
      const newData = { list: newListData?.reverse() } || [];
      newData.list = oldList.concat(newData.list);
      return {
        ...oldState,
        list: newData.list,
        currentPage: action.currentPage,
        lastPage: action.lastPage,
        total: action.total,
        per_page: action.per_page,
      };
    }
    default: {
      return state;
    }
  }
};

export default shiftsReducer;
